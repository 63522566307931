import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class overlayService {
    private sharedDataSubject = new BehaviorSubject<any[]>([]);
    sharedData$ = this.sharedDataSubject.asObservable();
  
    constructor() {}
  
    // This method performs the sorting operation and updates the shared data.
    sortAndSetData(dataToSort: any[]) {
      console.log('sort started')
      const sortedData = dataToSort.sort((a, b) => a.index - b.index);
      this.sharedDataSubject.next(sortedData);
      console.log('sortedData ' + sortedData);
      console.log('sort completed')
    }
}
