import { Component } from '@angular/core';
import { AuthService, UsersService } from '@yoyo/services'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['register.scss']
})
export class RegisterComponent {

  registerForm: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';
  regitering: boolean = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private userService: UsersService
  ) {}

   ngOnInit() {
    this.registerForm = this.fb.group({
      fName: ['', Validators.required ],
      lName: ['', Validators.required ],
      email: ['', Validators.required ],
      password: ['',Validators.required]
    });
  }

  tryRegister(value: any){
    console.log('value - CAREFUL : ' + JSON.stringify(value, null,2));
    const displayName = value.fName + ' ' + value.lName
    this.regitering = true;
      this.authService.doRegister(value)
      .then((res) => {
      //  console.log('registration response: ' + JSON.stringify(res,null,2));
        //this.authService.updateProfile(displayName);
          this.authService.updateProfile(displayName)
            .then(()=>{
              //console.log('profile updated')
              this.userService.initUserCollection()
                .then(()=>{
                  //console.log('collection updated')
                  this.authService.updateRoles()
                    .then((res) =>{
                      console.log('Role update: ' + JSON.stringify(res,null,2))
                      this.userService.initEligibility()
                        .then((res)=>{
                          console.log('res: ' + JSON.stringify(res,null,2));
                          this.authService.doLogin(value)
                            .then(()=>{
                              this.router.navigate(['/_init']);
                            })
                            .catch((err)=>{
                              console.log('err: '+JSON.stringify(err,null,2));
                            })
                        })
                        .catch((err)=>{
                          console.log('Err s3etting eligibility: ' + JSON.stringify(err,null,2));
                        })
                    })
                    .catch((err) =>{
                      console.log('Error on role set: ' + JSON.stringify(err,null,2));
                      this.router.navigate(['/unauthorised']);
                    })
                })
                .catch((err) =>{
                  console.log('Initialise collection err: ' + JSON.stringify(err,null,2))
                })
            })
            .catch((err) =>{
              console.log('Error setting profile dsiplay name: ' + JSON.stringify(err,null,2))
            });
        //this.errorMessage = "";
        //this.successMessage = "Your account has been created";
      }, err => {
        console.log(err);
        this.errorMessage = err.message;
        this.successMessage = "";
      })
      .finally(()=>{
     //   this.regitering = false;
      })
    }

}