import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplaceHostVariablePipe } from './config-string.pipe';

@NgModule({
  declarations: [ReplaceHostVariablePipe],
  imports: [CommonModule],
  exports: [ReplaceHostVariablePipe],
})
export class SharedPipesModule {}
