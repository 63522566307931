import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReactionService, AppStateService, HostStateService, TelemetryService} from '@yoyo/services';
import { ReactionStepDirective, manualDelay } from '@yoyo/shared';

import {
  ReactionModeStep,
  ReactionPermissionDeniedStep,
  ReactionPermissionRequestStep,
  ReactionRecordStep,
  ReactionSetupStep,
  ReactionWatchStep,
  ReactionResponseOptionsStep,
  ReactionRecordPostResponseStep,
  ReactionWriteResponseStep,
  ReactionOfferStep,
  RecordReviewStep,
  ReactionSlowStep,
  ReactionSimpleShowStep,
  ReactionLegalStep,
  ReactionConfigfailStep,
  ReactionRetryFaultStep,
  menu,
  ReactionEditor,
  thankyouStep,
  DynamicComponent
} from '@yoyo/pages';

export class StepItem {
  constructor(public component: Type<any>) {}
}

@Component({
  selector: 'create-reaction',
  templateUrl: './create.component.html',
})
export class CreateReactionComponent implements OnInit {          
 
  activeReactionStep: number;
  @ViewChild(ReactionStepDirective, { static: true })
  reaction_step: ReactionStepDirective;
  reaction_steps = [
    ReactionSetupStep,                  //0
    ReactionModeStep,                   //1
    ReactionWatchStep,                  //2
    ReactionPermissionRequestStep,      //3
    ReactionPermissionDeniedStep,       //4
    ReactionRecordStep,                 //5
    ReactionResponseOptionsStep,        //6
    ReactionRecordPostResponseStep,     //7
    ReactionWriteResponseStep,          //8
    ReactionOfferStep,                  //9
    RecordReviewStep,                   //10
    ReactionSlowStep,                   //11 
    ReactionSimpleShowStep,             //12
    ReactionLegalStep,                  //13
    ReactionRetryFaultStep,             //14
    ReactionConfigfailStep,             //15
    menu,                               //16
    ReactionEditor,                     //17
    thankyouStep,                       //18
  ];

  isResolvingAppState = true;
  has_validation_error = false;
  host_available: boolean = false;

  constructor(
    private ar: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appState: AppStateService,
    private rs: ReactionService,
    private host_state: HostStateService,
  //  private telemetry: TelemetryService,
  ) {
  //  this.telemetry.startRecordingLoad()
    this.host_available = this.host_state.host_available;
  }

  async ngOnInit() {
    console.log('pos_7');
   // this.telemetry.setInitialTime();
   // this.telemetry.startRecordingUser()
    if (!this.host_available) {
      console.log('Host not valid in component');
      this.isResolvingAppState = false;
      this.appState.reactionStepValue$.next(15);
      this.setInitialStepIndex();
    } else {
      const routeCheck = this.ar.snapshot.paramMap.get('id');
      if (routeCheck == '_init') {
        console.log('go for quick load');
        this.quickLoad()
      } else {
        await this.validateReactionId();  
      }
    }
  }

  private telemetryRecord(next: number) {
   // this.telemetry.stopRecordingLoad();
  //  this.telemetry.stopRecording(-1, 'Load', next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

  setInitialStepIndex() {
    this.telemetryRecord(0);
    this.appState.reactionStepValue$.subscribe((stepIndex) => {
      this.activeReactionStep = stepIndex;
 //     console.log('loading step' + stepIndex);
      this.loadStepperComponent(stepIndex);
    });
  }

  loadStepperComponent(stepIndex: number) {
    const currentStep = this.reaction_steps[stepIndex];
    const viewContainerRef = this.reaction_step.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent<DynamicComponent>(currentStep);
  }

  async validateReactionId() {
    try {
      const start_time = performance.now();
      const reaction_id = this.ar.snapshot.paramMap.get('id');
      console.log('The reaction id: ' +  reaction_id);
      if (!reaction_id) {
        this.setValidateError();
        return;
      }
      await this.rs.validateReactionId(reaction_id).subscribe(async res =>{
        this.appState.setReaction(res.reaction);
        const end_time = performance.now();
        const current_duration = end_time - start_time;
        if (current_duration < 1200) {          //this block seems to control duration of laoding screen.
          const diff = 1200 - current_duration;
         // console.log('COMPONENT - manually waiting...why tho?');
          //await manualDelay(diff);
        }
     //   await this.rs.initReactionSetup(reaction_id);
        this.isResolvingAppState = false;
      //  this.rs.reactionValid = true;
        this.appState.reactionReady = true;
        this.appState.reactionStepValue$.next(0);
        this.setInitialStepIndex();
      },
      (error) => {
        console.log('got me here')
        this.appState.reactionReady = false;
        this.isResolvingAppState = false;
     //   this.rs.reactionValid = false;
        this.appState.reactionStepValue$.next(15);
        this.setInitialStepIndex();
      }
      );
    } catch (err) {
      this.setValidateError();
      this.isResolvingAppState = false;
    }
  }

  quickLoad(){        //used to skip validation
      this.isResolvingAppState = false;
    //  this.rs.reactionValid = true;
      this.appState.reactionReady = true;
      if (this.appState.preview) {
        this.appState.reactionStepValue$.next(0);
      } else {
        this.appState.reactionStepValue$.next(17);
      }
      this.setInitialStepIndex();    
  }



  setValidateError(): void {
    this.has_validation_error = true;
    console.log('component sees validation error');
    // JM TODO - Handle Error

    // this.system_error_details = {
    //   title: 'Invalid Reaction ID',
    //   description:
    //     'Something has gone wrong, Try accessing the link in your email again or contact our support team',
    // };
  }
}
