import { Component, AfterViewInit } from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import { HostStateService, AppStateService, TelemetryService } from '@yoyo/services';
import {DynamicComponent} from '../setup/setup.component';

@Component({
  selector: 'step-mode',
  templateUrl: './mode.component.html',
})
export class ReactionModeStep implements  DynamicComponent, AfterViewInit {      //step 1
  current_reaction: Reaction;
  current_host_config: AppHost;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private telemetry: TelemetryService,
  ) {
    this.telemetry.startRecordingLoad();
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(1, this.current_host_config?.app_content?.experience?.step?.step1?._module, next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

  routeToRecord() {
    // Route To Permissions
    //this.app_state.cameraState = 'ready';
    this.app_state.cameraOkReturnStep = 5;        //this will set that if permission ok then come back to live recording
    this.app_state.cameraFailReturnStep = 2;        //this will set that if fails then go back to
    this.telemetryRecord(3);
    this.app_state.reactionStepValue$.next(3);
  }

  routeToWatchFirst() {
    // Route To Watch
    this.telemetryRecord(2);
    this.app_state.reactionStepValue$.next(2);
  }
}
