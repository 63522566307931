import { Component, OnInit } from '@angular/core';
import { AuthService} from '@yoyo/services';

@Component({
    selector: 'unauthorised',
    templateUrl: './unauthorised.component.html',
    styleUrls: ['unathorised.scss']
  })

export class unauthorised implements  OnInit { 
    userName: string;
    constructor(private auth: AuthService,){}

    ngOnInit() {
        console.log("Unauthorsed init");
        this.userName = this.auth.getuser().displayName
    }
}