import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStateService } from '@yoyo/services';
import * as firebase from 'firebase';
import { environment } from '@yoyo/env';

export class DataAccessException extends Error {
  message: string;
  private cause: unknown;

  constructor(message: string, cause: unknown) {
    super(message);
    this.cause = cause;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CloudStorageService {


  constructor( private http: HttpClient, private appState: AppStateService) {}

  initFirebase() {

    if (!firebase.default.apps.length) {
 //     console.log('INITIALISING IN THE SERVICE');
      const fbConfig = environment.firebase;
      firebase.default.initializeApp(environment.firebase);
      
    }else {
//      console.log('INITIALISIED ELSEWHERE');
      firebase.default.app(); // if already initialized, use that one
    }
  }

  async upload_file(path: string, file: Blob) : Promise<firebase.default.storage.UploadTask>{
    try {
      const ref = firebase.default.storage().ref(`${path}`);
      var metadata = {
        contentType: 'video/webm',
      };
      return await ref.put(file, metadata);
    } catch (e) {
      throw new DataAccessException('cannot upload file',e );
    }
  }


  async upload_filev2(path: string, file: Blob) : Promise<firebase.default.storage.UploadTask>{
    try {
      const ref = firebase.default.storage().ref(`${path}`);
      //console.log('Got ref: '+ JSON.stringify(ref,null,2));

      const contentType = this.getFileContentType(file);
        var metadata = {
        contentType: contentType,
      };
     // console.log('got content type');
      const result = await ref.put(file, metadata);
      return result;
    } catch (e) {
      console.log('error is: '+ e);
      throw new DataAccessException('cannot upload file',e );
    }
  }

  getFileContentType(file: Blob): string {
    // Use the File or Blob's type property to get the content type
    return file.type;
  }

  

  async retrive_file(path: string) {
    try {
      const ref = firebase.default.storage().ref(`${path}`);
      return await ref.getDownloadURL();
    } catch (e) {
      throw e;
    }
  }


  async userUploadFileHandler(e: ProgressEvent, files: File, fileName: string, widthIn?: number, heightIn?: number, scaleIn?: boolean): Promise<any>  {
    //console.log('fileName: ' + fileName);
    const width = widthIn ?? 1080
    const height = heightIn ?? 605
    const scale = scaleIn ?? true;
    console.log('scale: ' + scale);
    const saveLoc = this.appState.fileRoot + environment.fileSystem.gifts + fileName;   //set to gifts. 
    console.log('saveLoc: ' + saveLoc);
    return new Promise<string>(async (resolve, reject) => {
      const arrayBuffer = (e.target as FileReader).result as ArrayBuffer;
      const img = new Image();
      const uint8Array = new Uint8Array(arrayBuffer);
      const blob = new Blob([uint8Array], { type: files.type });
      const url = URL.createObjectURL(blob);
      img.src = url;
      img.onload = () => {
        console.log('image loaded');
        if (scale){
          if (!fileName.toLowerCase().endsWith('.gif')){
            const scaleFactorWidth = width / img.width;
            const scaleFactorHeight = height / img.height;
            const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);
            const newWidth = img.width * scaleFactor;
            const newHeight = img.height * scaleFactor;

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');

            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, (canvas.width - newWidth) / 2, (canvas.height - newHeight) / 2, newWidth, newHeight);
          
            canvas.toBlob(async (blob) => {
              //console.log('go with scale')
              try{
                const permURl = await this.processUserFile(blob, files, saveLoc);
                resolve(permURl)
              } catch (error) {
                reject('Failed')
              }
            }, files.type);
          } else {
            //console.log('go without scale bc gif')
            try {
              const permURl = this.processUserFile(blob, files, saveLoc);
              resolve(permURl)
            } catch (error) {
              reject('Failed')
            }
          }
        } else {
          //console.log('go without scale')
          try {
             const permURl = this.processUserFile(blob, files, saveLoc);
             resolve(permURl)
           } catch (error) {
             reject('Failed')
           }
        }
      }
    })
  }


  async processUserFile (blob: Blob, files: File, saveLoc: string) {
    const path = saveLoc
    try {
      const fileUp = await this.upload_filev2(path, blob);

      try {
        const permURLretrieved = await this.getPermUrl(path);
        console.log("Perm URL Retrieved:", JSON.stringify(permURLretrieved,null,2));
        return permURLretrieved

      } catch (error) {
        console.error('Error retrieving Perm URL:', error);
        throw error
      }
    } catch (e) {
      //console.log('Not so good: ' + e);
      //const permURLretrieved = await this.getPermUrl(path);
      //console.log("Perm URL Retrieved in the e:", JSON.stringify(permURLretrieved,null,2));
      //return permURLretrieved
      throw e
    }
  };



  async getPermUrl(path: string) : Promise<any> {
    try{
      const response = await this.http
      .post<{}>(
          environment.api.host.permURL,
          {
            "filePath":path
          },
          {
              headers: {
                  ['Content-Type']: 'application/json',
              },
              responseType: 'json',
          }
      )
      .toPromise();
      return response; // Return the response
    } catch(e){
      console.error('Error:', e);
      throw e
    }
  }




}
