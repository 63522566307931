import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {AppHost, Reaction} from '@yoyo/types';
import * as RecordRTC from 'recordrtc';
import {
  HostStateService,
  AppStateService,
  ReactionService,
} from '@yoyo/services';
import {PlayerNoControlsComponent, manualDelay} from '@yoyo/shared';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'webcam-recorder',
  templateUrl: './webcamRecorder.component.html',
})
export class WebcamRecorderComponent implements OnInit, OnChanges, OnDestroy {

   reaction_data: Reaction;
  @ViewChild('webcam_video')  webcam_video:  ElementRef<HTMLVideoElement>;

  // Record RTC
  private stream: MediaStream;
  private recorder: any;
  devicesError = false;
  host_config: AppHost;
  private currentTime: number;
  private timer: number = 0;

  constructor(
      private rs: ReactionService
  ) {

  }

  @Input()  startRecording: boolean;
  @Input()  imageBeforeRecording: string;
  @Input()  time: number;
  @Input()  duration: number;
  @Output() currentTimeOfWebCamRecording = new EventEmitter<string>();
  @Output() finshedRecordingWebcam = new EventEmitter<boolean>();
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    //console.log('WEBCAM - Change detected');
    if (changes?.startRecording?.currentValue  ) {
      try {
        if (this.recorder) {
          return;
        }
        const s = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        this.stream = s;
        const selected_webcam_stream: HTMLVideoElement =
            this.webcam_video.nativeElement;
        selected_webcam_stream.srcObject = this.stream;
        selected_webcam_stream.muted = !selected_webcam_stream.muted;
        selected_webcam_stream.autoplay = !selected_webcam_stream.autoplay;
        this.startReactionRecording();

        setInterval(() =>{
            this.timer+=0.5;
            this.currentTimeOfWebCamRecording.emit(this.timer.toString());
        }, 500);

      } catch (error) {
        console.error('Error: ngOnInit()', error);
      }
    }else if(  changes?.startRecording?.currentValue  === false){
 //     console.log('WEBCAM - stop part 1');
      if (this.recorder) {
  //      console.log('WEBCAM - stop part 2');
        this.timer=0;
        this.webcam_video.nativeElement.currentTime = 0;
        this.recorder.stopRecording(this.processVideo.bind(this));
        this.webcam_video.nativeElement.pause()
        this.webcam_video.nativeElement.srcObject=null;
        this.webcam_video.nativeElement.load();
      }
    }
  }
  async processVideo(): Promise<void> {
    this.stopMedia();
    const blob = await this.recorder.getBlob();
    this.recorder = null;
    await this.onReactionComplete(blob);
  }

  async onReactionComplete(value: Blob): Promise<void> {
    try {
   //   console.log('WEBCAM - Reaction Complete');
      this.rs.reaction_recording_blob = value;
      this.recorder = null;
      this.stream = null;
      this.finshedRecordingWebcam.emit(true);
    } catch (err) {
   //   console.log('Error - onReactionComplete(): ', err);
    }
  }


  private stopMedia(): void {
 //   console.log('WEBCAM - stopMedia - called');
    if (this.recorder && this.stream) {
      this.stream.getAudioTracks().forEach((track) => track.stop());
      this.stream.getVideoTracks().forEach((track) => track.stop());
    }
  }


  async ngOnInit() {
//    console.log('WEBCAM - initiated');
    try {
      this.devicesError = await this.checkEnumerateDevices();
      if (!!this.devicesError) {
        alert('Device not supported!');
        // TODO JM: Handle error on DOM
      }
      await manualDelay(6000);
    } catch (error) {
      console.error('Error: ngOnInit()', error);
    }
  }



  private startReactionRecording(): void {
  //  console.log('WEBCAM - Started');
    this.recorder = new RecordRTC(this.stream, {
      type: 'video',
      mimeType: 'video/webm;codecs=h264',
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 128000,
      });
    this.recorder.startRecording();
  }

  async checkEnumerateDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (Array.isArray(devices) && !!devices.length) {
  //      console.log('DEVICES DETECTED');
        return false;
      } else {
        return true;
      }
    } catch (err) {
      return true;
    }
  }

  @HostListener('unloaded')
  ngOnDestroy() {
 //   console.log("WEBCAM - destroy")
  }

  updateCurrentTime = () => {
    this.currentTime = this.timer;
  }
  percentageComplete = () =>   Math.round(((this.time? this.time: this.currentTime) / this.duration) * 100);

}
