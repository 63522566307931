import {Component, ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {AppHost, Reaction} from '@yoyo/types';
import {AppStateService, DownloadService, HostStateService, TelemetryService} from '@yoyo/services';

export interface DynamicComponent {}

@Component({
  selector: 'step-setup',
  templateUrl: './setup.component.html',
})
export class ReactionSetupStep implements DynamicComponent, OnInit, AfterViewInit {            
  current_reaction: Reaction;
  current_host_config: AppHost;
  private nextStep: number;
  @ViewChild('aTag') aTag: ElementRef;

  private reactionFail: boolean = true;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private elmRef: ElementRef,
    private downloads: DownloadService,
 //   private telemetry: TelemetryService,
  ) {
 //   this.telemetry.startRecordingLoad();
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
   // console.log('current_reaction '+ JSON.stringify(this.current_reaction, null,2));
  }

  async ngAfterViewInit() {
 //   this.telemetry.stopRecordingLoad();
//    this.telemetry.startRecordingUser();
  //  console.log('step telemetery is: ' + JSON.stringify(this.telemetry.telemetryData.steps, null,2));
  }

  async ngOnInit() {
    console.log('Init setup.');
   // console.log('Reaction is: ' + JSON.stringify(this.current_reaction, null,2));
    //console.log('current_host_config is: ' + JSON.stringify(this.current_host_config, null,2));




    let downloadTimeLessThanThreshold = false;
 //   this.telemetry.initialise();
    if (this.current_host_config?.Perfomance?.sampleFileURL) {
      const downloadObservable = this.downloads.download(this.current_host_config?.Perfomance?.sampleFileURL)
      downloadObservable.subscribe((event) => {
        if (event.state === 'DONE') {
        //  console.log("time to download sample image in milliseconds "+ event.timeInMillisecondsToDownload );
          if (event.timeInMillisecondsToDownload < this.current_host_config?.Perfomance?.thresholdMS) {
            downloadTimeLessThanThreshold = true;
            this.nextStep = 1
          } else {
            this.nextStep = 11
        }
       }
      })
    } else {
      console.log("warning - performance configuration not found");
      this.nextStep = 1
    }
  }

  private telemetryRecord(next: number) {
 //   this.telemetry.stopRecording(0, this.current_host_config.app_content?.experience?.step?.step0?._module, next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }


  routeToNext() {
      this.telemetryRecord(this.nextStep);
      this.app_state.reactionStepValue$.next(this.nextStep);      //normal = 1
  }
}
