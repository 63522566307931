import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/create/components/steps/login/login.component';
import { LandingComponent } from './pages/create/components/steps/landing/landing.component';
import { RegisterComponent } from './pages/create/components/steps/register/register.component';
import { unauthorised } from './pages/create/components/steps/unauthorised/unauthorised.component';
import { BrokenStep } from './pages/create/components/steps/broken/broken.component';
import { ResetComponent } from './pages/create/components/steps/reset/reset.component';
import { CommonModule } from '@angular/common'


import {
  SharedComponentsModule,
  SharedPipesModule,
  ReactionStepDirective,
} from '@yoyo/shared';
import { LayoutsModule } from '@yoyo/layouts';

import {
  CreateReactionComponent,
  ReactionSetupStep,
  ReactionModeStep,
  ReactionPermissionRequestStep,
  ReactionPermissionDeniedStep,
  ReactionRecordStep,
  ReactionWatchStep,
  ReactionResponseOptionsStep,
  ReactionRecordPostResponseStep,
  ReactionWriteResponseStep,
  ReactionOfferStep,
  RecordReviewStep,
  ReactionSlowStep,
  ReactionSimpleShowStep,
  ReactionLegalStep,
  ReactionRetryFaultStep,
  ReactionConfigfailStep,
  menu,
  ReactionEditor,
  ViewList,
  thankyouStep
} from '@yoyo/pages';
import { NgCircleProgressModule } from 'ng-circle-progress';

const reaction_steps = [
  ReactionSetupStep,
  ReactionModeStep,
  ReactionPermissionRequestStep,
  ReactionPermissionDeniedStep,
  ReactionRecordStep,
  ReactionWatchStep,
  ReactionResponseOptionsStep,
  ReactionRecordPostResponseStep,
  ReactionWriteResponseStep,
  ReactionOfferStep,
  RecordReviewStep,
  ReactionSlowStep,
  ReactionSimpleShowStep,
  ReactionLegalStep,
  ReactionRetryFaultStep,
  ReactionConfigfailStep,
  menu,
  ReactionEditor,
  LoginComponent,
  RegisterComponent,
  unauthorised,
  BrokenStep,
  ResetComponent,
  ViewList,
  thankyouStep,
  LandingComponent
];

const components = [
  CreateReactionComponent,
  ReactionStepDirective,
  ...reaction_steps,
];

@NgModule({
  declarations: [AppComponent, ...components],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedComponentsModule,
    HttpClientModule,
    LayoutsModule,
    SharedPipesModule,
    ReactiveFormsModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
