import {Component} from '@angular/core';
import {AppHost, Reaction} from '@yoyo/types';
import {AppStateService, HostStateService, TelemetryService} from '@yoyo/services';
import {DynamicComponent} from '../../setup/setup.component';

@Component({
  selector: 'step-configfail',
  templateUrl: './configfail.component.html',
})
export class ReactionConfigfailStep implements DynamicComponent  {   //step 15
  current_reaction: Reaction;
  current_host_config: AppHost;
  host_available: boolean; 
  reactionReady: boolean;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
  ) {
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
    this.host_available = this.host_state.host_available;
    this.reactionReady = this.app_state.reactionReady
  }

}