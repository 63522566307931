import { Component, OnInit } from '@angular/core';
import { AuthService} from '@yoyo/services';

@Component({
    selector: 'broken',
    templateUrl: './broken.component.html',
    styleUrls: ['broken.scss']
  })

export class BrokenStep implements  OnInit { 
    userName: string;
    constructor(private auth: AuthService,){}

    ngOnInit() {
       // console.log("anything");
        console.log("Broken init");
        this.userName = this.auth.getuser().displayName
    }    
}