import { Component, OnInit } from '@angular/core';
import { ReactionService } from '@yoyo/services';
import {DynamicComponent} from '../setup/setup.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@yoyo/env';

interface userReacts {
  'index': number,
  'name' : string,
  'Gift': string,
  'URL': string
}

@Component({
  selector: 'step-viewlist',
  templateUrl: './viewlist.component.html',
  styleUrls: ['viewlist.scss']
})


export class ViewList implements  DynamicComponent, OnInit {      //step 17

  ReactionsInitStatus: userReacts[] = [];
  ReactionsWatchedStatus: userReacts[] = [];
  ReactionsOtherStatus: userReacts[] = [];

  noReaction: boolean = false;
  loading: boolean = true

  constructor(
    private rs: ReactionService,
    private fb: FormBuilder,
    private router: Router,

  ) {
  //  this.current_reaction = this.app_state.current_reaction;
   // this.current_host_config = this.host_state.currentHostConfig;
  }

  ngOnInit() {
    console.log('init viewList');
    this.getListofReactions();
  }

  getListofReactions() {
    this.rs.getUserReactions()
                .subscribe((response)=>{
                  console.log('User reactions result is: ' + JSON.stringify(response, null,2))
                  this.processReturn(response.reactions)
                },
                error=> {
                  console.error('Error saving user reaction entry: ' + JSON.stringify(error, null,2));
                })
    }

    processReturn(input: any[]){
      if (input !== null && Array.isArray(input) && input.length > 0) {
        console.log('got values');
        for (let i = 0; i < input.length; i++) {
          if (input[i].status == 'initial') {
            const reactionURL = environment.url.view + input[i].id;
            const newItem: userReacts = { 'index' : i+1,
                                          'name': input[i].receiver,
                                          'Gift': input[i].gift,
                                          'URL' : reactionURL
                                        }
            this.ReactionsInitStatus.push(newItem)
          }
          this.noReaction = false
        }
      } else {
        console.log('no values');
        this.noReaction = true
        console.log('no result');
      }
      this.loading = false;
    }

    gotoMenu(){
      this.router.navigate(['/_init']);
    }
}
