import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { LoaderComponent } from './loader.component';
//import { PlayerNoControlsComponent } from './player-no-controls.component';
//import { PlayerControlsComponent } from './player-controls.component';
//import { PlayerReviewComponent } from './player-review.component';
import { ButtonComponent } from './button.component';
import {WebcamRecorderComponent} from './webcamRecorder.component';
//import {ProgressBarComponent} from './progressBar.component';


const components = [
  HeaderComponent,
  LoaderComponent,
 // PlayerNoControlsComponent,
 // PlayerControlsComponent,
 // PlayerReviewComponent,
  ButtonComponent,
  WebcamRecorderComponent,
 // ProgressBarComponent
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule],
  exports: [...components],
})
export class SharedComponentsModule {}
