import { Component, OnInit } from '@angular/core';
import { AuthService } from '@yoyo/services'
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'reset',
  templateUrl: './reset.component.html',
  styleUrls: ['reset.scss']
})
export class ResetComponent implements OnInit {

  resetPswdForm: FormGroup;
  errorMessage: string = '';
  loggingIn: boolean = false;
  emailValid: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.resetPswdForm = this.fb.group({
      email: ['', [Validators.required, Validators.email ]],
    });

    this.resetPswdForm.valueChanges.subscribe(value => {
      this.emailValid = this.resetPswdForm.controls.email.valid
    })
  }

  pswdReset(){
    this.loggingIn = true;
    const email = this.resetPswdForm.get('email').value;
    console.log(email);
    this.authService.pswdReset(email)
      .then(()=>{
        this.router.navigate(['/login']);
      })
      .catch((err)=>{
        this.resetPswdForm.patchValue({ email: null });
        //console.log('Password reset error: ' + JSON.stringify(err,null,2));
        this.loggingIn = false;
      })
  }

  gotoLogin(){
    this.router.navigate(['/login']);
  }
}