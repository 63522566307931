import { Component, OnInit } from '@angular/core';
import { AuthService, UsersService } from '@yoyo/services'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['landing.scss']
})
export class LandingComponent implements OnInit {

  loginForm: FormGroup;
  errorMessage: string = '';
  loggingIn: boolean = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private userService: UsersService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required ],
      password: ['',Validators.required]
    });
  }


  gotoLogin(){
    this.router.navigate(['/login']);
  }

  gotoRegister(){
    this.router.navigate(['/register']);
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required ],
      password: ['',Validators.required]
    });
  }


  googleLogin(){
    this.authService.doGoogleLogin()
    .then(res => {
      if (res.additionalUserInfo.isNewUser){
        this.setUpNewUser();
      } else {
        this.router.navigate(['/_init']);
      }
    })
  }


tryLogin(value: any){
    this.loggingIn = true;
    this.authService.doLogin(value)
    .then(res => {
   //   console.log('Should be logged in')
      this.loggingIn = false;
      this.router.navigate(['/_init']);

    }, err => {
      this.loggingIn = false;
      console.log(err);
      this.errorMessage = err.message;
    })
  }

  gotoMenu(){
    this.router.navigate(['/_init']);
  }


  setUpNewUser(){

    this.userService.initUserCollection()
        .then(()=>{
            this.authService.updateRoles()
                .then((res) =>{
                    console.log('Role update: ' + JSON.stringify(res,null,2))
                    this.userService.initEligibility()
                    .then((res)=>{
                      console.log('res: ' + JSON.stringify(res,null,2));
                      this.router.navigate(['/_init']);
                    })
                    .catch((err)=>{
                      console.log('Err setting eligibility: ' + JSON.stringify(err,null,2));
                      this.router.navigate(['/unauthorised']);
                    })
                })
                .catch((err) =>{
                  console.log('Error on role set: ' + JSON.stringify(err,null,2));
                  this.router.navigate(['/unauthorised']);
                })
            })
            .catch((err) =>{
              console.log('Initialise collection err: ' + JSON.stringify(err,null,2))
              this.router.navigate(['/unauthorised']);
            })
        }

    pswdReset(){
      this.router.navigate(['/reset']);
    }

}