import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import {  HostStateService,  AppStateService,  ReactionService, TelemetryService} from '@yoyo/services';
import {DynamicComponent} from '../../setup/setup.component';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'step-record-response',
  templateUrl: './recordpost.component.html',
})
export class ReactionRecordPostResponseStep implements OnInit, DynamicComponent, OnDestroy, AfterViewInit {       //this is step 7
  current_reaction: Reaction;
  reaction_data: Reaction;
  current_host_config: AppHost;
  isXLBreakpoint = false;

 // Overlays
 revealContentShown: boolean = false;
 reveal_overlay_content = '';
 private config_ordered: any;
 private currentLoopIndex: number = 0;
 private timeLeft: number = 0;
 private timeConsumed: number = 0;
 private destroy$: Subject<void> = new Subject<void>();

 //Progress
 percentProgVal: number = 0;
 startZero: boolean = true;
 spinTitle: string = "";
 spinner_duration = 0;
 prog_animation = false;
 overallDuration: number = 0;
 private spinLoopMaxCount: number = 0;
 private spinLoopCurrentCount: number = 0;
 private spinDecrement: number = 100;    //this is the await value in the spin - feeds into the spinLoopCount

 // State Controls
 is_reveal_video_ready = false;
 has_reaction_started = false;
 devicesError = false;
 host_config: AppHost;
 videoDuration: number;
 private isPaused: boolean = true;
 private videoCompleted: boolean = false;
 permission_given: boolean = true;
 startBtnState: boolean = true;
 private exitCondition: boolean = false;
 private stepGoto: number;


 //button labels
 btnTxt_primary_init: string = "";
 btnTxt_scnd_init: string = "";
 btnTxt_primary_act: string = "";
 btnTxt_scnd_act: string = "";
 actionBtn: string = "pause";  
 startBtnText: string = "Not ready";  
 gobackBtnText: string = "Not ready";



  // video
  videoPoster: string;
  webCamPoster: string;
  watchBG: string
  public startRecording: boolean = false

  // Record RTC
  private stream: MediaStream;
  private recorder: any;
  protected duration: number;
  private display_reveal = true;

 // Overlays
  show_reveal_content = false;
  private overlayTimerPaused: boolean = false;
  private remainingAwaitTime: number | null = null;
  
  // Controls
  has_device_error = false;
  recording_ready = false;
  has_recording_started = false;
  pauseBtn: string = "pause";
  
  // Record RTC
  private currentTime: number;
  public outTime: number;


  @ViewChild('reveal_video', { static: true })
  reveal_video!: ElementRef<HTMLVideoElement>;

  constructor(
      private root_state: AppStateService,
      private app_state: AppStateService,
      private host_state: HostStateService,
      private reaction_service: ReactionService,
      private rs: ReactionService,
      private renderer: Renderer2,
      private el: ElementRef,
      private telemetry: TelemetryService,
  ) {
    this.telemetry.startRecordingLoad();
    this.reaction_data = this.root_state.current_reaction;
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
    this.attemptPermissionCheck();
    this.host_config = this.host_state.currentHostConfig;
    this.duration = this.host_config.time_control.post_record_length;
    this.root_state.redoButtonToPointToStep = 7;
    this.webCamPoster = this.host_config?.video_posters?.post_webCam_poster;
  }


//**********        LifeCycle hooks */
//**********        LifeCycle hooks */
//**********        LifeCycle hooks */
//**********        LifeCycle hooks */

  async ngOnInit() {
    console.log("RECPOST - initialised");
    console.log("has_reaction_started= " + this.has_reaction_started + " is_reveal_video_ready= " + this.is_reveal_video_ready);

    this.btnTxt_primary_init = this.host_config.app_content.experience.step.step7.prmy_btn_loading;
    this.btnTxt_scnd_init = this.host_config.app_content.experience.step.step7.scnd_btn_int;
    this.btnTxt_primary_act = this.host_config.app_content.experience.step.step7.prmy_btn_act;
    this.btnTxt_scnd_act = this.host_config.app_content.experience.step.step7.scnd_btn_act; 

    this.checkImgForBG().then((imageUrl) => {
      this.watchBG = imageUrl;
    }).catch((error) => {
        this.watchBG = 'assets/images/watchOverlayBG.png';
    });

    try {
      this.devicesError = await this.checkEnumerateDevices();
      if (!!this.devicesError) {
        alert('Device not supported!');
        // TODO JM: Handle error on DOM
      }
      this.is_reveal_video_ready = true;
    } catch (error) {
      console.error('Error: ngOnInit()', error);
    }

    const termsLink = this.el.nativeElement.querySelector('#termsLink');

    if (termsLink) {
      this.renderer.listen(termsLink, 'click', (event) => {
        console.log('link listened to');
        this.endReaction(false);
        event.preventDefault();
        this.app_state.returnStep = 7;
        this.telemetryRecord(13);
        this.app_state.reactionStepValue$.next(13);
      });
    }

    if (window.innerWidth >= 1024){
      console.log('size is xl because: ' + window.innerWidth);
      this.isXLBreakpoint = true;
    } else {
      this.isXLBreakpoint = false;
    //   console.log('size is not xl because: ' + window.innerWidth);
    }

    this.config_ordered = this.host_config?.overlays?.exp_post?.sort(
      (a, b) => a.index - b.index
    );
   // console.log('config_ordered: ' + JSON.stringify(this.config_ordered, null, 2));

    //check that this is how to do it
    this.startBtnState = true;
    this.startBtnText = "Start";
    this.gobackBtnText = "Back";
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(7, this.current_host_config?.app_content?.experience?.step?.step7?._module, next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

  ngOnDestroy() {
    console.log('RECORD - destroy');
    this.destroy$.next();
    this.destroy$.complete();
  }


//**********        this is the progress code block */
//**********        this is the progress code block */
//**********        this is the progress code block */
//**********        this is the progress code block */

private async setupProgessSpinner() {
  this.overallDuration = this.duration ;
  console.log('overallDuration: ' + this.overallDuration);
  this.spinner_duration = this.overallDuration * 1000;
  //this.percentProgVal = this.overallDuration;
  this.spinLoopMaxCount = this.spinner_duration / this.spinDecrement;
  console.log('spinLoopMaxCount is set at: ' + this.spinLoopMaxCount);

  this.spinnerBurnDown();
};

private async spinnerBurnDown() {
  let curPercent = 0;
  for (let counter = this.spinLoopCurrentCount; counter < this.spinLoopMaxCount;) {
    curPercent = (this.spinLoopCurrentCount/this.spinLoopMaxCount) * 100;
    this.percentProgVal = 100 - curPercent;
 //   console.log('SPINNER - percentProgVal: ' + this.percentProgVal + ' spinLoopCurrentCount= ' + this.spinLoopCurrentCount + ' spinLoopMaxCount= ' + this.spinLoopMaxCount);
    this.spinTitle = Math.round((this.percentProgVal/100) * this.overallDuration).toString();
    
    await this.delay(this.spinDecrement);
    if (this.exitCondition) {
      console.log('RECPOST - spinnerBurn down exit condition')
      return;
    } else {
        if (this.isPaused){
          return    //exist this loop becaue pause duration is unknown.  Will start again on resume.
      } else {
        counter++;
        this.spinLoopCurrentCount = counter;
      }

      if (counter >= this.spinLoopMaxCount){
        console.timeLog('Burndown complete');
        this.endReaction(false);        //false means process ended via the timer completing.  Note true means users exited
      }
    };
  }
}

private async delay(ms: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}


//**********        this is the overlay code block */
//**********        this is the overlay code block */
//**********        this is the overlay code block */
//**********        this is the overlay code block */

  private async startCountdown(waiting: number): Promise<number> {
    console.log('starting the time for: ' + waiting);
    let timeConsumedLocal: number = 0;
    const completedPromise = new Promise<number>((resolve) => {
      interval(100)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(count => {
          const remainingTime = waiting - count / 10;
          timeConsumedLocal = count / 10;
          this.timeLeft = Math.max(remainingTime, 0);
          if (this.timeLeft <= 0) {
            resolve(0);
          }
        });

        this.destroy$.subscribe(() => {
          console.log('destroy$ emitted in startCountdown');
            resolve(timeConsumedLocal); // Resolve the promise when destroy$ is emitted
        });
    });
    return await completedPromise; // Wait until the promise is resolved (countdown is completed)
  }

  private async OverlayLoopController() {
    console.log('OVERLAY - loop about to start from: ' + this.currentLoopIndex);
    for (let counter = this.currentLoopIndex; counter < this.config_ordered.length;) {
      //console.log('config is: ' + JSON.stringify(this.config_ordered[i], null, 2));
      if (this.isPaused) {
        // Pause the loop if the overlay timer is paused
        this.currentLoopIndex = counter;
        console.log('paused loop exit. ' + this.currentLoopIndex);
        return;
      }

      if(!this.revealContentShown) {      //false means we must be waiting to show
          const waiting = (this.config_ordered[counter].show_at/1000) - this.timeConsumed;
    //     console.log('OVERLAY - SHOW block true. Stats: waiting='+waiting + ' config val='+this.config_ordered[counter].show_at+' timeConsumed=' + this.timeConsumed);
          this.timeConsumed = await this.startCountdown(waiting) +  this.timeConsumed;
    //     console.log("SHOW - await complete with timeConsumed = " + this.timeConsumed);
          
          if (this.exitCondition) {
            console.log('RECPOST - OverlayLoopController down exit condition @shown await')
            return;
          } else {
              if (this.isPaused){
                console.log('doing nothing because paused');
              } else {
                this.timeConsumed = 0;
                this.revealContentShown = true;
        //        console.log('Show phase complete for loop: ' + this.currentLoopIndex);
              }
          }
      }
      
      if (this.revealContentShown){
          this.reveal_overlay_content = this.replaceConfigString(this.config_ordered[counter].message);
          const waiting = (this.config_ordered[counter].hide_at/1000) - this.timeConsumed;
    //      console.log('OVERLAY - HIDE block true. Stats: waiting='+waiting + ' config val='+this.config_ordered[counter].hide_at+' timeConsumed=' + this.timeConsumed);
          this.timeConsumed = await this.startCountdown(waiting) +  this.timeConsumed;
  //      console.log("HIDE - await complete with timeConsumed = " + this.timeConsumed);
          if (this.exitCondition) {
            console.log('PECPOST - OverlayLoopController down exit condition @shown await')
            return;
          } else {           
            if (this.isPaused){
              console.log('doing nothing because paused');
            } else {
              this.timeConsumed = 0;
              this.revealContentShown = false;
      //       console.log('Hide phase complete for loop: ' + this.currentLoopIndex);
              this.currentLoopIndex++;
              counter++;
            }
          }
        }
      console.log('loop end with currentLoopIndex: ' + this.currentLoopIndex);
    }     //for loop closing bracket
  }       //OverlayLoopController

  private replaceConfigString(string_value: string) {
    return string_value
      .replace(
        '{{sender.first_name}}',
        this.reaction_data?.sender_details?.first_name
      )
      .replace(
        '{{sender.last_name}}',
        this.reaction_data?.sender_details?.last_name
      )
      .replace(
        '{{receiver.first_name}}',
        this.reaction_data?.receiver_details?.first_name
      )
      .replace(
        '{{gift_name}}',
        this.reaction_data?.product?.product_details?.label || ''
      )
      .replace(
        '{{gift_image}}',
        this.reaction_data?.product?.product_details?.image_src || ''
      )
      .replace(
        '{{gift_description}}',
        this.reaction_data?.product?.product_details?.product_description || ''
      )
      .replace('{{gift_message}}', this.reaction_data?.gift_message || '');
  }

  private async checkEnumerateDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (Array.isArray(devices) && !!devices.length) {
        console.log('Device detected');
        return false;
      } else {
        return true;
      }
    } catch (err) {
      return true;
    }
  }


  //**********        this is the control block */
  //**********        this is the control block */
  //**********        this is the control block */
  //**********        this is the control block */

  private async startReveal(): Promise<void> {
    console.log("start reveal")
    try {
      if (this.recorder) {            //purpose unclear
        return;
      }
      this.startRecording = true;
      this.has_reaction_started = true;
      this.isPaused = false;
      this.startZero = false;
    } catch (error) {
      console.error('Error: ngOnInit()', error);
    }
  }

  private ActionControl() {
    console.log('SPINNER - percentProgVal: ' + this.percentProgVal + ' spinLoopCurrentCount= ' + this.spinLoopCurrentCount + ' spinLoopMaxCount= ' + this.spinLoopMaxCount);
    if (this.isPaused) {        //paused so resuming
      console.log('CONTROL - state = paused -> action = resume.')
      this.actionBtn = "Pause"
      this.isPaused = false;
      this.prog_animation = true;
      this.OverlayLoopController();
      this.spinnerBurnDown();                                                    
    } else {                                          //video still playing so resuming
      console.log('CONTROL - state = playing -> action = pause')
      this.actionBtn = "Resume"
      this.prog_animation = false;
      this.isPaused = true;
      this.destroy$.next();
    }
  }

  async onReactionComplete(value: Blob): Promise<void> {      //unclear on callign function
    try {
      console.log('CONTROL - Reaction Complete');
      this.rs.reaction_recording_blob = value;
      this.recorder = null;
      this.stream = null;
    } catch (err) {
      console.log('CONTROL - Error - onReactionComplete(): ', err);
    }
  }

  private startReaction() {
    console.log('CONTROL - Starting....')
    this.startReveal();
    this.OverlayLoopController();
    this.setupProgessSpinner();
    this.prog_animation = true;
    this.isPaused = false;
    this.spinLoopCurrentCount = 0;
    this.currentLoopIndex = 0;
    this.stepGoto = 10;           //set to default next transition step
  }

  private endReaction(userExit: boolean){
    console.log('CONTROL - end.  User initiated: ' + userExit);
    this.startRecording = false;
    this.exitCondition = true;
    this.spinLoopCurrentCount = this.spinLoopMaxCount + 1;
    this.currentLoopIndex = this.config_ordered.length + 1;
    console.log('CONTROL - Exit stat: spinloop count: ' +this.spinLoopCurrentCount + ' overlay count: ' + this.currentLoopIndex );
  }
  
  moveToNextStep($event: boolean) {       //this will be called by the webcam component when it is finished.
    console.log('CONTROL - webcam think it is all done.')
    this.telemetryRecord(this.stepGoto);
    this.app_state.reactionStepValue$.next(this.stepGoto);
  }


  //**********        this is html exposed functions */
  //**********        this is html exposed functions */
  //**********        this is html exposed functions */
  //**********        this is html exposed functions */

  btnStart() {
    console.log('btnStart');
    this.startReaction();
  }

  btnBack() {
    console.log('btnBack');
    this.telemetryRecord(6);
    this.app_state.reactionStepValue$.next(6)
  }

  btnRestart() {
    console.log('BTN - Restart');
    this.endReaction(true);
    this.stepGoto = 7;      //this step number
    console.log('BTN - Restart - route to: ' + this.stepGoto);
    this.telemetryRecord(this.stepGoto);
    this.app_state.reactionStepValue$.next(this.stepGoto);
  }

  btnAction() {
    console.log('btnAction');
    this.ActionControl()
  }

  btnDone() {
    console.log('btnDone');
    this.stepGoto = 10;
    this.endReaction(true);           //true measn the user choose to complete early.
  }




//**********        this is helper block */
//**********        this is helper block */
//**********        this is helper block */
//**********        this is helper block */

  private checkImgForBG(): Promise<string> {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        console.log('WATCH BG image ok');
        resolve(this.reaction_data?.product?.product_details?.image_src);
      };

      img.onerror = () => {
        console.log('WATCH BG image not configured.  Reverting to local.');
        resolve('assets/images/watchOverlayBG.png');
      };

      img.src = this.reaction_data?.product?.product_details?.image_src ;
    });
  }

  onVideoMetadataLoaded() {
    const videoElement: HTMLVideoElement = this.reveal_video.nativeElement;
    this.videoDuration = videoElement.duration;
    console.log("Video duration: " + this.videoDuration);
  }

  public updateCurrentVideoTime() {           
        //this is unnecasary on this component
  }



















//Original


//time

webCamTime(webCamTime: string) {
  this.currentTime = parseFloat(webCamTime);
  this.outTime = this.currentTime; 
  // console.log('webcam output time: ' + this.outTime);
  this.percentProgVal = 100 - (Math.round((this.currentTime / (this.spinner_duration / 1000)) * 100));

  //this.percentProgVal = 100 - (Math.round((this.currentTime / (this.spinner_duration / 1000)) * 100));

  //console.log("Time is: " + this.currentTime  + "percentProgVal: " + this.percentProgVal +  "spinner_duration: " + this.spinner_duration );
  this.spinTitle = Math.round((this.overallDuration/1000) - this.currentTime).toString();
}

  //******* Permission code block start
  async attemptPermissionCheck(): Promise<void> {
    try {
      const mediaConstraints: MediaStreamConstraints = {
        video: true,
        audio: true,
      };
      await navigator.mediaDevices.getUserMedia(mediaConstraints);
      await this.onPermissionAccept();
    } catch (e) {
      await this.onPermissionDeny();
   //  await this.onPermissionAccept();  //testing only  REMOVE
    }
  }

  async onPermissionAccept(): Promise<void> {
    try {
      this.permission_given = true;
    } catch (e) {
      console.log('onPermissionAccept() Error', e);
      // JM TODO - Handle App Failure
    }
  }

  async onPermissionDeny(): Promise<void> {
    try {
      this.telemetryRecord(4);
      this.app_state.reactionStepValue$.next(4);
    } catch (e) {
      console.log('onPermissionAccept() Error', e);
      // JM TODO - Handle App Failure
    }
  }


}
