import {Component, AfterViewInit} from '@angular/core';
import {AppHost, Reaction} from '@yoyo/types';
import {AppStateService, HostStateService, TelemetryService} from '@yoyo/services';
import {DynamicComponent} from '../../setup/setup.component';

@Component({
  selector: 'step-slow',
  templateUrl: './simpleshow.component.html',
})
export class ReactionSimpleShowStep implements DynamicComponent,AfterViewInit  {      //step 12
  current_reaction: Reaction;
  current_host_config: AppHost;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private telemetry: TelemetryService,
  ) {
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(12, this.current_host_config?.app_content?.experience?.step?.step12?._module, next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

  prmyBtn(){
    this.telemetryRecord(8);
    this.app_state.reactionStepValue$.next(8);
  }

  scndBtn(){
    this.telemetryRecord(1);
    this.app_state.reactionStepValue$.next(1);
  }



}