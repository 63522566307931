import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHost } from '@yoyo/types';
import { environment } from '@yoyo/env';
import { AuthService } from '@yoyo/services';
import { StringifyOptions } from 'querystring';

@Injectable({
    providedIn: 'root',
  })

export class UsersService {
  
    constructor(private http: HttpClient,
                private authService: AuthService
            ) {}

    async getUserHostsFromCloud() {
        const uid = this.authService.getuser().uid;
        try {
        const authToken =  'Bearer ' + this.authService.getAuthServiceToken();
        //  console.log('authToken ' + authToken);
        const response = await this.http
            .post<any>(
            environment.api.users.getUsers,
            {
                'uid':uid
            },
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : authToken,
                },
                responseType: 'json',
            }
            )
            .toPromise();        
        return response;
        } catch (e) {
        console.log('error is: ' + JSON.stringify(e, null, 2));
        throw e;
        }
    }

    async addHostToUserCollection(hostName: string) {
        const uid = this.authService.getuser().uid;
        try {
        const authToken =  'Bearer ' + this.authService.getAuthServiceToken();
        //  console.log('authToken ' + authToken);
        const response = await this.http
            .post<any>(
            environment.api.users.addHost,
            {
                'uid':uid,
                'hostName' : hostName
            },
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : authToken,
                },
                responseType: 'json',
            }
            )
            .toPromise();        
        return response;
        } catch (e) {
        console.log('error is: ' + JSON.stringify(e, null, 2));
        throw e;
        }
    }


    async initUserCollection() {
        const user = this.authService.getuser()
        try {
        const authToken =  'Bearer ' + this.authService.getAuthServiceToken();
        //  console.log('authToken ' + authToken);
        const response = await this.http
            .post<any>(
            environment.api.users.initDoc,
            {
                'uid': user.uid,
                'name' : user.displayName,
                "email" : user.email,
                "source" : "hostEditor"
            },
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : authToken,
                },
                responseType: 'json',
            }
            )
            .toPromise();        
        return response;
        } catch (e) {
            console.log('error is: ' + JSON.stringify(e, null, 2));
        throw e;
        }
    }

    async initEligibility() {
        const user = this.authService.getuser();
        let baseHost: string
        if (environment.production){
            baseHost = 'yoyo.reply.gift'
        } else {
            baseHost = 'yoyo.dev.reply.gift'
        }
        try {
        const authToken =  'Bearer ' + this.authService.getAuthServiceToken();
        //  console.log('authToken ' + authToken);
        const response = await this.http
            .post<any>(
            environment.api.eligibility.set,
            {
                "email" : user.email,
                "hostName" : baseHost,
                "canEdit" : "false"
            },
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : authToken,
                },
                responseType: 'json',
            }
            )
            .toPromise();        
        return response;
        } catch (e) {
            console.log('error is: ' + JSON.stringify(e, null, 2));
        throw e;
        }
    }


    async getEligibility() {
        const user = this.authService.getuser();
        try {
        const authToken =  'Bearer ' + this.authService.getAuthServiceToken();
        //  console.log('authToken ' + authToken);
        const response = await this.http
            .post<any>(
            environment.api.eligibility.get,
            {
                "email" : user.email,
            },
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : authToken,
                },
                responseType: 'json',
            }
            )
            .toPromise();        
        return response;
        } catch (e) {
            console.log('error is: ' + JSON.stringify(e, null, 2));
        throw e;
        }
    }
}

