import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import { HostStateService, AppStateService, ReactionService, CloudStorageService, AuthService } from '@yoyo/services';
import {DynamicComponent} from '../setup/setup.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
//import {makeId} from '@yoyo/shared';


@Component({
  selector: 'step-editor',
  templateUrl: './editor.component.html',
})
export class ReactionEditor implements  DynamicComponent, OnInit {      //step 1
  current_reaction: Reaction;
  new_reaction: Reaction;
  current_host_config: AppHost;
  reactionId: string = '';
  imgSrc: string = '';
  imgUploading: boolean = false;
  fileUpMsg: string ="Use to pick local file"
  postToCloud: boolean = false;
  posting: boolean = false;

  overlayForm: FormGroup;
  overlayState: string = 'giftNameShow';
  overLayOutput: string = '';
  overlayShow : boolean = true;

  reactSetupForm: FormGroup;
  chgNotSaved: boolean = false;

  selectedFile: File;
  file: File;
  statusArray: string[] = ['Press validate when you have filled in the form.'];

  giftmsgLength = 170
  giftLblLength = 30
  giftmsgHelper: string = ''
  giftDescHelper: string = ''
  giftLabelHelper: string = ''

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private fb: FormBuilder,
    private rs: ReactionService,
    private store: CloudStorageService,
    private auth: AuthService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
    this.new_reaction = this.rs.initiliseBlankReaction();
  }

  ngOnInit() {
    //console.log('Current user: '+ JSON.stringify(this.auth.getuser().uid, null,2));
    this.reactionId = this.current_reaction.id;
    this.imgSrc = this.current_reaction.product.product_details.image_src;
    this.giftmsgHelper = this.giftmsgLength + ' characters out of ' + this.giftmsgLength + ' left.'
    this.giftDescHelper = this.giftmsgLength + ' characters out of ' + this.giftmsgLength + ' left.'
    this.giftLabelHelper = this.giftLblLength + ' characters out of ' + this.giftLblLength + ' left.'

    this.reactSetupForm = this.fb.group({
      senderName: [this.current_reaction.sender_details.first_name || ''],
      receiverName: [this.current_reaction.receiver_details.first_name||''],
      giftName: [this.current_reaction.product.product_details.label],
      giftMsg: [this.current_reaction.gift_message],
      giftImg: [this.current_reaction.product.product_details.image_src],
      giftDscrpt: [this.current_reaction.product.product_details.product_description],
      reactionId: [],
      
    });

    this.overlayForm = this.fb.group({
      overlayState: []
    })

    this.overlayForm.valueChanges.subscribe(value => {
      this.overlayState = value.overlayState;
      this.overLayOutput = this.current_reaction.product.product_details.label + ': '  + this.current_reaction.product.product_details.product_description
      //this.updateOverlay();
    })


    this.reactSetupForm.valueChanges.subscribe(value => {
      //console.log('value.giftDscrpt ' + value.giftDscrpt);
      this.postToCloud = false;
      this.statusArray = ['Press validate when you have filled in the form.'];
      this.chgNotSaved = true;
      //this.mapOutUpdate(value);
      this.current_reaction.sender_details.first_name = value.senderName;
      this.current_reaction.receiver_details.first_name = value.receiverName;
      this.current_reaction.product.product_details.label = value.giftName;
      this.current_reaction.gift_message = value.giftMsg;
      this.current_reaction.product.product_details.image_src = value.giftImg;
      this.imgSrc = this.current_reaction.product.product_details.image_src;
      this.current_reaction.product.product_details.product_description = value.giftDscrpt;
      let giftMsgCharsLeft: number
      if (!value.giftMsg) {
         giftMsgCharsLeft = this.giftmsgLength;
      } else {
        giftMsgCharsLeft = this.giftmsgLength - value.giftMsg.length;
      }
      let giftDescCharsLeft: number
      if (!value.giftDscrpt) {
        giftDescCharsLeft = this.giftmsgLength;
      } else {
        giftDescCharsLeft = this.giftmsgLength - value.giftDscrpt.length;
      }
      let giftLblCharsLeft: number
      if (!value.giftName) {
        //console.log('not there')
        giftLblCharsLeft = this.giftLblLength;
      } else {
        //console.log('hereXYZ')
        giftLblCharsLeft = this.giftLblLength - value.giftName.length;
      }
      this.giftmsgHelper = giftMsgCharsLeft + ' characters out of ' + this.giftmsgLength + ' left.' 
      this.giftDescHelper = giftDescCharsLeft + ' characters out of ' + this.giftmsgLength + ' left.' 
      this.giftLabelHelper = giftLblCharsLeft + ' characters out of ' + this.giftLblLength + ' left.' 
      this.overLayOutput = this.current_reaction.product.product_details.label + ': '  + this.current_reaction.product.product_details.product_description
      //this.updateOverlay();
    });
    

    this.updateOverlay();
    this.chgNotSaved = false;

    const termsLink = this.el.nativeElement.querySelector('#termsLink');

    if (termsLink) {
      this.renderer.listen(termsLink, 'click', (event) => {
        //console.log('link listened to');
        event.preventDefault();
        this.app_state.reactionStepValue$.next(13);
      });
      }






  }

  updateOverlay() {
    if (this.overlayState === 'blank') {
      this.overLayOutput='';
      this.overlayShow = false;
    };
    if (this.overlayState === 'giftDscrbShow') {
      this.overLayOutput = this.current_reaction.product.product_details.product_description;
      this.overlayShow = true;
    };
    if (this.overlayState === 'giftMessageShow') {
      this.overLayOutput = this.current_reaction.gift_message;
      this.overlayShow = true;
    };
    if (this.overlayState === 'giftNameShow'){
      this.overLayOutput = this.current_reaction.product.product_details.label;
      this.overlayShow = true;
    };    
  }

/*
  setId(input?: string){
    if (input === null || input === undefined) {
        return makeId(6)
    } else {
        return this.new_reaction.receiver_details.first_name
    }
}
*/


  buildOutput() {
    this.new_reaction.id = this.current_reaction.id;
    this.new_reaction.receiver_details.first_name = this.current_reaction.receiver_details.first_name;
    this.new_reaction.sender_details.first_name = this.current_reaction.sender_details.first_name;
    this.new_reaction.host_id =  this.host_state.currentHostName;
    this.new_reaction.hostData.hostname =  this.host_state.currentHostName;
    this.new_reaction.hostData.host_orderId =  "not set";
    this.new_reaction.hostData.hostLock =  false;
    this.new_reaction.metaData.source =  "Manual";
    this.new_reaction.metaData.userId =  this.auth.getuser().uid;
    this.new_reaction.product.product_details.label = this.current_reaction.product.product_details.label;
    this.new_reaction.product.product_details.product_description = this.current_reaction.product.product_details.product_description;
    this.new_reaction.product.product_details.image_src = this.current_reaction.product.product_details.image_src;
    this.new_reaction.gift_message = this.current_reaction.gift_message;
    //console.log('this.new_reaction: ' + JSON.stringify(this.new_reaction, null,2));
  }
 

  async fileUploaded(event: Event) {
    this.imgUploading = true;
    const target = event.target as HTMLInputElement;
    const files = target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(files);
    reader.onload = (e) => {
      //console.log('file loaded with files.name: '+ files.name);
      this.store.userUploadFileHandler(e as ProgressEvent, files as File, files.name as string)
        .then((res) => {
         // console.log('should be good');
         if (files.name.toLowerCase().endsWith('.gif')){
            this.uiUpdate(res.url, 'gif')
         } else {
            this.uiUpdate(res.url, 'ok')
         }
        })
        .catch((err) =>{
          console.log('error: '+ err)
          this.uiUpdate('skip', 'fault')
        })
    }
  }

    uiUpdate(url: string, status: string){
      //console.log('update vals');
      if (status == 'ok'){
        this.fileUpMsg = "File uploaded"
        this.current_reaction.product.product_details.image_src = url;
        this.reactSetupForm.patchValue({ giftImg: url });
      }
      if (status == 'gif'){
        this.fileUpMsg = "Gif ok but check scale"
        this.current_reaction.product.product_details.image_src = url;
        this.reactSetupForm.patchValue({ giftImg: url });
      }
      if (status == 'fault'){
        this.fileUpMsg = "Fault - not updated"
      }
      this.imgUploading = false;
    }



























  postConfig(){
    this.posting = true
    this.statusArray = ['Posting configuration to cloud.  Please wait for URL'];
    //console.log('this.new_reaction: '+ JSON.stringify(this.new_reaction,null,2));
    this.rs.newReaction(this.new_reaction)
          .subscribe(
            updatedConfig => {
              console.log('Reaction saved successfully', updatedConfig);
              //this.postToCloud = false;
              this.rs.userReactionUpsert(this.new_reaction)
                .subscribe((response)=>{
                  console.log('User reaction uodated responded with: ' + JSON.stringify(response, null,2))
                  this.posting = false;
                  this.statusArray = ['Your gift is succssfully posted'];
                  this.statusArray.push('the URL is:');
                  //this.statusArray.push(this.buildURL());
                  this.router.navigate(['/thanks']);
                },
                error=> {
                  this.posting = false;
                  console.error('Error saving user reaction entry: ' + JSON.stringify(error, null,2));
                })
            },
            error => {
              this.posting = false;
              console.error('Error saving reaction: ' + JSON.stringify(error, null,2));
            }
          );
  }

  buildURL(){
    const url = "https://" + this.host_state.currentHostName + '/v1/' + this.current_reaction.id;
    console.log("'url: " + url);
    return url;
  }

  validate(){
    console.log('Validate and save');
    this.buildOutput();
    this.checkSetup()
  }

  

  preview(){
    console.log('preview')
    this.app_state.preview = true;
    this.router.navigate(['/v1/_init'])
    this.app_state.reactionStepValue$.next(0);
  }

  checkSetup(){
    this.statusArray = ['Pre post validation is: '];
    if (!this.new_reaction.sender_details.first_name){
      this.statusArray.push('X - Set a sent by name');
    }
    if (!this.new_reaction.receiver_details.first_name){
      this.statusArray.push('X - Set a giving to name');
    }
    if (!this.new_reaction.product.product_details.label){
      this.statusArray.push('X - Set Gift short name');
    }
    if (!this.new_reaction.gift_message){
      this.statusArray.push('X - no message has been entered');
    } 
    if (this.new_reaction.product.product_details.image_src == 'assets/images/giftPlaceHolder.png') {
      this.statusArray.push('X - Gift image is default please change')
    }
    if (!this.new_reaction.product.product_details.product_description){
      this.statusArray.push('X - No description of gift');
    }
    if (this.statusArray.length === 1){
      this.statusArray.push('Good to go!');
    } else {
      this.statusArray.push('Some problems with this setup have been identified.');
      this.statusArray.push('Update or continue anway?');
    }
    this.postToCloud = true;
  }

  private checkImg(): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(true);
      };

      img.onerror = () => {
        console.log('WATCH BG image not configured.  Reverting to local.');
        resolve(false);
      };

      img.src = this.new_reaction.product.product_details.image_src;
    });
  }












}
