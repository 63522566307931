import {
    HttpEvent,
    HttpEventType,
    HttpProgressEvent,
    HttpResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { distinctUntilChanged, scan, map, tap } from "rxjs/operators";

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(
    event: HttpEvent<unknown>
): event is HttpProgressEvent {
    return (
        event.type === HttpEventType.DownloadProgress ||
        event.type === HttpEventType.UploadProgress
    );
}

export interface Download {
    timeInMillisecondsToDownload: number | null;
    progress: number;
    state: "PENDING" | "IN_PROGRESS" | "DONE";
}

export function download(
): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {

    const now = new Date();
    return (source: Observable<HttpEvent<Blob>>) =>
        source.pipe(
            scan(
                // tslint:disable-next-line:no-shadowed-variable
                (download: Download, event): Download => {
                    if (isHttpResponse(event)) {
                        const afterDownload = new Date();
                        // @ts-ignore
                        const number = Math.abs(afterDownload - now);
                        return {
                            progress: 100,
                            state: 'DONE',
                            timeInMillisecondsToDownload: number
                        };
                    }
                    return download;
                },
                { state: "PENDING", progress: 0, timeInMillisecondsToDownload: null }
            ),
            distinctUntilChanged((a, b) => a.state === b.state
                && a.progress === b.progress
                && a.timeInMillisecondsToDownload === b.timeInMillisecondsToDownload
            )
        );
}
