// Imported Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimaryLayout } from './primary.layout';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '@yoyo/shared';

const components = [PrimaryLayout];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, RouterModule, SharedComponentsModule],
  exports: [...components],
})
export class LayoutsModule {}
