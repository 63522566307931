import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `
    <button
      [ngClass]="btn_type === 'primary' ? 'host-primary-btn' : 'host-accent-btn'"
      class="font-bold py-2 px-4 rounded-md min-w-min focus:outline-none "
      [disabled]="disabled"
      (click)="onButtonClick.emit()"
    >
      {{ btn_txt }}
    </button>
  `,
})
export class ButtonComponent {
  @Input() btn_txt = '';
  @Input() btn_type = 'primary';
  @Input() disabled = false; 

  @Output() onButtonClick = new EventEmitter();

  constructor() {}
}
