import { BehaviorSubject, Observable } from 'rxjs';

export function Empty$<T>(cacheKey: string): AuthStateProperty<T> {
  const subject = new BehaviorSubject<T>(null as any);
  const stateProp: AuthStateProperty<T> = {
    subject,
    cacheKey,
    reset: () => subject.next(null as any),
  };
  return stateProp;
}

export function GetVal<T>(stateProp: AuthStateProperty<T>): T {
  return stateProp.subject.getValue();
}

export function GetVal$<T>(stateProp: AuthStateProperty<T>): Observable<T> {
  return stateProp.subject.asObservable();
}

export function SetVal<T>(stateProp: AuthStateProperty<T>, data: T): void {
  stateProp.subject.next(data);
  localStorage.setItem(stateProp.cacheKey, JSON.stringify(data));
}

export function TrySetValFromCache<T>(stateProp: AuthStateProperty<T>): void {
  try {
    const cacheValString = localStorage.getItem(stateProp.cacheKey) as string;
    const cacheValParsed = JSON.parse(cacheValString);
    stateProp.subject.next(cacheValParsed);
  } catch (error) {}
}

interface AuthStateProperty<T> {
  subject: BehaviorSubject<T>;
  cacheKey: string;
  reset: () => void;
}
