import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {AuthService} from '@yoyo/services';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
 //   console.log('guards - get the gaurds');
    if (this.authService.getuser().uid) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}