import { Component, OnInit, AfterViewInit} from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import { HostStateService, AppStateService, TelemetryService } from '@yoyo/services';
import {DynamicComponent} from "../../setup/setup.component";

@Component({
  selector: 'step-denied-permission',
  templateUrl: './denied.component.html',
})
export class ReactionPermissionDeniedStep implements DynamicComponent, OnInit, AfterViewInit  {      //step 4
  current_reaction: Reaction;
  current_host_config: AppHost;
  cameraStatus: string;
  failStep: number;
  deniedNextBtn: string;
  pageTitle: string;
  pageBody: string;
  valSet: boolean = false;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private telemetry: TelemetryService,
  ) {
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
    this.failStep = this.app_state.cameraFailReturnStep;
  }

  ngOnInit(): void {
    console.log('this.app_state.cameraState: ' + this.app_state.cameraState);


    //default
    this.pageTitle = this.current_host_config?.app_content?.experience?.step?.step4?.heading_other
    this.pageBody = this.current_host_config?.app_content?.experience?.step?.step4?.body_write_other;  

    if (this.app_state.cameraState == 'NotAllowedError: Permission denied') {
      this.valSet = true;
      this.pageTitle = this.current_host_config?.app_content?.experience?.step?.step4?.heading_denied
      if (this.app_state.cameraOkReturnStep === 7) {
        this.pageBody = this.current_host_config?.app_content?.experience?.step?.step4?.body_write_denied;
        this.deniedNextBtn = this.current_host_config?.app_content?.experience?.step?.step4?.prmy_btn_write  
      } else {
        this.pageBody = this.current_host_config?.app_content?.experience?.step?.step4?.body_watch_denied;
        this.deniedNextBtn = this.current_host_config?.app_content?.experience?.step?.step4?.prmy_btn_watch;  
      }
    }; 
    if (this.app_state.cameraState == 'NotFoundError: Requested device not found'){
      this.valSet = true;
      this.pageTitle = this.current_host_config?.app_content?.experience?.step?.step4?.heading_noCamera;
      if (this.app_state.cameraOkReturnStep === 7) {
        this.pageBody = this.current_host_config?.app_content?.experience?.step?.step4?.body_write_noCamera;
        this.deniedNextBtn = this.current_host_config?.app_content?.experience?.step?.step4?.prmy_btn_write    
      } else {
        this.pageBody = this.current_host_config?.app_content?.experience?.step?.step4?.body_watch_noCamera;
        this.deniedNextBtn = this.current_host_config?.app_content?.experience?.step?.step4?.prmy_btn_watch;  
      };
    };
    if(!this.valSet){
      this.pageTitle = this.current_host_config?.app_content?.experience?.step?.step4?.heading_other;
      if (this.app_state.cameraOkReturnStep === 7) {
        this.pageBody = this.current_host_config?.app_content?.experience?.step?.step4?.body_write_other;
        this.deniedNextBtn = this.current_host_config?.app_content?.experience?.step?.step4?.prmy_btn_write    
      } else {
        this.pageBody = this.current_host_config?.app_content?.experience?.step?.step4?.body_watch_other;
        this.deniedNextBtn = this.current_host_config?.app_content?.experience?.step?.step4?.prmy_btn_watch;  
      };
    }
    this.cameraStatus = this.app_state.cameraState;  
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(4, this.current_host_config?.app_content?.experience?.step?.step4?._module, next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }


  routeToPlayVideo() {
    this.telemetryRecord(this.failStep);
    this.app_state.reactionStepValue$.next(this.failStep);
  }

  tryAgain() {
    this.telemetryRecord(3)
    this.app_state.reactionStepValue$.next(3);
  }
}
