import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppStateService, ReactionService, HostStateService, TelemetryService} from '@yoyo/services';
import {AppHost, Reaction} from '@yoyo/types';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-record-review',
  templateUrl: './record-review-step.component.html',
})
export class RecordReviewStep implements OnInit,  AfterViewInit  {
    @ViewChild('webcam_video')  webcam_video:  ElementRef<HTMLVideoElement>;
    cameraSrc: any = undefined;
    videoId: string;
    current_reaction: Reaction;
    current_host_config: AppHost;
    private type: string = "unknown";

  constructor(
      private rs: ReactionService,
      private domSanitizer: DomSanitizer,
      private host_state: HostStateService,
      private app_state: AppStateService,
      private telemetry: TelemetryService,
    ) {
      this.telemetry.startRecordingLoad();
      this.current_reaction = this.app_state.current_reaction;
      this.current_host_config = this.host_state.currentHostConfig;
    }

    async ngOnInit() {                        //step 10
        console.log("blob"+ this.rs.reaction_recording_blob)
        this.cameraSrc = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.rs.reaction_recording_blob));
        //this.upload()
    }

    async ngAfterViewInit() {
        this.telemetry.stopRecordingLoad();
        this.telemetry.startRecordingUser();
        await this.webcam_video.nativeElement.play();
        if (this.app_state.redoButtonToPointToStep === 5){
            this.type = "live"
        }
        if (this.app_state.redoButtonToPointToStep === 7){
            this.type = "post"
        }
    }

    private telemetryRecord(next: number) {
        this.telemetry.stopRecording(10, this.current_host_config?.app_content?.experience?.step?.step10?._module, next);
       // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
      }

    async upload() {
        const uploadedWebcamRecording = await this.rs.uploadWebcamRecording('completed_reaction_video_ids')           //unclear on why the text is passed in.  This will generate and return a 32 bit guid
        uploadedWebcamRecording.subscribe({
            complete: () => {
                console.log('REVIEW - upload emitted complete');
            },
            error: (err) => {
                console.log('REVIEW - obeserved error: ' + err);
            }
        })
    }

    SubmitButtonHandler() {
        console.log('REVIEW - send button ');
        /*
        this.telemetryRecord(-9);
        this.telemetry.setUploadTime();
        this.telemetry.setStatus("video sent");
        this.telemetry.postTelemetry().subscribe();     //this will post the telemetry.
   //     console.log('step telemetery is: ' + JSON.stringify(this.telemetry.telemetryData, null,2));
        
   
        this.upload()
        this.rs.recordFileSent(this.type).subscribe();
        this.rs.convertFinalVideo();
        */
        this.app_state.reactionStepValue$.next(9);
    }

    RedoButtonClickHandler() {
        console.log("redo wired");
        this.telemetryRecord(this.app_state.redoButtonToPointToStep);
        this.app_state.reactionStepValue$.next(this.app_state.redoButtonToPointToStep);

    }
}
