import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, timer, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private pausedSubject = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();

  constructor() {}

  getTimerObservable(): Observable<number> {
    return this.pausedSubject.pipe(
      switchMap((paused) => {
        if (paused) {
          return new Observable<number>(); // Return an empty Observable when paused
        } else {
          return timer(0, 1000).pipe(
            takeUntil(this.destroy$) // Stop the timer when destroyed
          );
        }
      })
    );
  }

  isPaused(): boolean {
    return this.pausedSubject.value;
  }

  setPaused(paused: boolean): void {
    this.pausedSubject.next(paused);
  }

  async pauseableDelay(ms: number): Promise<void> {
    if (this.isPaused()) {
      return new Promise<void>((resolve) => {
        const pauseCheckInterval = setInterval(() => {
          if (!this.isPaused()) {
            clearInterval(pauseCheckInterval);
            resolve();
          }
        }, 100); // Check every 100ms for pause/resume
      });
    } else {
      return new Promise<void>((resolve) => {
        setTimeout(resolve, ms);
      });
    }
  }

  stopTimer(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
