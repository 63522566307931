const baseUrl = 'https://us-central1-yoyoreact-dev.cloudfunctions.net/';
const rootLoc = 'getuser().email'

export const environment = {
  production: false,
  version: '1.0.0',
  firebase: {
    apiKey: 'AIzaSyBpjelLELFHhPdMPgcbsNXMNpGIW17b6bY',
    authDomain: 'yoyoreact-dev.firebaseapp.com',
    projectId: 'yoyoreact-dev',
    storageBucket: 'yoyoreact-dev.appspot.com',
    messagingSenderId: '895012247591',
    appId: '1:895012247591:web:ce3444264b0c53a2dc5602',
  },
  account: {
    email: 'service_account_frontend@reply.gift',
    password: "UBC6O41JsdULuR706L0lWz",
  },
  hosts: {
    default: 'yoyo.dev.reply.gift',
    view: 'yoyo.dev.reply.gift'
  },
  url: {
    view: 'https://view.dev.reply.gift/v1/'
  },
  api: {
    reaction: {
      create: baseUrl + 'reaction/create',
      verify: baseUrl + 'reaction/verify',
      initialise: baseUrl + 'reaction/initialise',
      update: baseUrl + 'reaction/update',
      send: baseUrl + 'reaction/send',
      agree: baseUrl + 'reaction/agree',
      convert: baseUrl + 'reaction/convert',
      telemetry: baseUrl + 'telemetry/upsert',
      new: baseUrl + 'reaction/new',
    },
    host: {
      upsert: baseUrl + 'host/upsert',
      retrieve: baseUrl + 'host/retrieve',
      permURL: baseUrl + 'host/permURL'
    },
    auth: {
      getRoles: baseUrl + 'authcheck/roleGet',
      getUserInfoByUID: baseUrl + 'authcheck/getUserInfoUID',
      assignRole: baseUrl + 'authcheck/roleset'
    },
    users: {
      getUsers: baseUrl + 'users/gethosts',
      addHost: baseUrl + 'users/addhost',
      initDoc: baseUrl + 'users/initdoc',
      upsertReaction: baseUrl + 'users/upsertReaction',
      getUserReactions: baseUrl + 'users/getUserReactions'
    },
    eligibility: {
      get: baseUrl + 'eligibilty/getHostsByUser',
      set: baseUrl + 'eligibilty/upsert'
    },
  },
  fileSystem : {
    root: rootLoc,
    gifts: '/gifts/',
    hosts: '/hosts/'
  }
};
