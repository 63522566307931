import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppStateService } from '@yoyo/services';

@Injectable({
  providedIn: 'root',
})
export class ReactionRootStateGuard implements CanActivate {
  constructor(private root_state: AppStateService) {}

  async canActivate(): Promise<boolean> {
    try {
      this.initFromCache();
      if (!this.root_state.current_reaction) {
        throw new Error('No reaction found in cache...');
      }
      return true;
    } catch (error) {
      console.log('Error: StateGuard(): ', error);
      this.root_state.clearStorage();
      // await this.appRouter.routeToSetup();
      throw new Error(error.toString());
    }
  }

  private async initFromCache(): Promise<void> {
    this.root_state.retrieveReactionFromCache();
  }
}
