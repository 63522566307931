import { Component, } from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import { HostStateService, AppStateService, ReactionService } from '@yoyo/services';
import {DynamicComponent} from '../setup/setup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'step-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['thankyou.scss']
})
export class thankyouStep implements  DynamicComponent {      //step 1
  current_reaction: Reaction;
  current_host_config: AppHost;
  url: string = '';

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private rs: ReactionService,
    private router: Router,
  ) {
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
  }

  ngOnInit(): void {
    this.url = this.buildURL()
  }

  routeToRecord() {
    this.app_state.reactionStepValue$.next(3);
  }

  routeToWatchFirst() {
    this.app_state.reactionStepValue$.next(2);
  }


  preview(){
    console.log('preview')
    this.app_state.preview = true;
    this.router.navigate(['/v1/view'])
  //  this.app_state.reactionStepValue$.next(0);
  }


  buildURL(){
    const url = "https://" + this.host_state.currentHostName + '/v1/' + this.current_reaction.id;
    console.log("'url: " + url);
    return url;
  }

  restartReact(){
    this.rs.reaction = this.rs.initiliseBlankReaction();
    this.app_state.setReaction(this.rs.reaction);
    this.router.navigate(['/v1/_init'])
  }

}
