import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { download, Download } from './download'
import { Observable } from 'rxjs'

@Injectable({providedIn: 'root'})
export class DownloadService {

    constructor(
        private http: HttpClient,
    ) {
    }

    download(url: string, filename?: string): Observable<Download> {
        return this.http.get(url, {
            reportProgress: true,
            observe: 'events',
            responseType: 'blob'
        }).pipe(download())
    }


    blob(url: string, filename?: string): Observable<Blob> {
        return this.http.get(url, {
            responseType: 'blob'
        })
    }
}
