import {Component, AfterViewInit} from '@angular/core';
import {AppHost, Reaction} from '@yoyo/types';
import {AppStateService, HostStateService, TelemetryService} from '@yoyo/services';
import {DynamicComponent} from '../../setup/setup.component';

@Component({
  selector: 'step-legal',
  templateUrl: './legal.component.html',
})
export class ReactionLegalStep implements DynamicComponent, AfterViewInit {    //step 13
  current_reaction: Reaction;
  current_host_config: AppHost;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private telemetry: TelemetryService,
  ) {
    this.telemetry.startRecordingLoad();
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(13, 'legal', next);
    //console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

  routeToNext() {
    //console.log('pressed the button in the legal page');
    //console.log('return step is: ' + this.app_state.returnStep);
    this.telemetryRecord(17);
    this.app_state.reactionStepValue$.next(17);
  }
}
    
