import {Component, AfterViewInit} from '@angular/core';
import {AppHost, Reaction} from '@yoyo/types';
import {AppStateService, HostStateService, TelemetryService} from '@yoyo/services';
import {DynamicComponent} from '../../setup/setup.component';

@Component({
  selector: 'step-slow',
  templateUrl: './slow.component.html',
})
export class ReactionSlowStep implements DynamicComponent, AfterViewInit  {   //step 11
  current_reaction: Reaction;
  current_host_config: AppHost;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private telemetry: TelemetryService,
  ) {
    this.telemetry.startRecordingLoad();
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(11, this.current_host_config?.app_content?.experience?.step?.step11?._module, next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

  prmyBtn(){
    this.telemetryRecord(12);
    this.app_state.reactionStepValue$.next(12);
  }

  scndBtn(){
    this.telemetryRecord(1);
    this.app_state.reactionStepValue$.next(1);
  }
}