import { Component } from '@angular/core';
import { AppHost } from '@yoyo/types';
import { HostStateService, AppStateService } from '@yoyo/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  template: `
    <div *ngIf="host_available" 
      class="bg-white w-full flex flex-row items-center p-1 shadow-md justify-center xl:justify-start"
    >
      <div class="w-32">
        <img
          [src]="this.host_config?.theme.logo_url"
          class="w-full h-full xl:ml-4"
        />
      </div>
      <div class="ml-auto">
      <button
        (click)="gotoMenu()"
        class="mt-4 w-full mt-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
        Menu
      </button>
  </div>
    </div>
  `,
})
export class HeaderComponent {
  host_config: AppHost;
  host_available: boolean = false;
  constructor(
    public host_state: HostStateService,
    private app_state: AppStateService,
    private router: Router,
    ){
    this.host_config = this.host_state.currentHostConfig;
    this.host_available = this.host_state.host_available;
  }

  gotoMenu(){
    this.router.navigate(['/_init']);
  }
}
