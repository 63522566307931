import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { AppHost } from '@yoyo/types';
import { environment } from '@yoyo/env';
import { Empty$, GetVal, GetVal$, SetVal } from '@yoyo/shared';
import { AuthService } from '@yoyo/services';
import { Observable } from 'rxjs';
import { rejects } from 'assert';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root',
})
export class HostStateService  {
  private host_config = Empty$<AppHost>('host_config');
  private host_name = Empty$<string>('host_name');
  host_available: boolean = false;
  private reactionId: string ="";

  constructor(private http: HttpClient, private authService: AuthService) {}
  
  async initHostEnvironment() {
    console.log("pos_2");
    var checkHost = document.location.hostname;
    var setHost;
    if (checkHost.includes("ngrok")) {
      setHost = "localhost"
    } else {
        setHost = document.location.hostname;
    }

  // setHost = "localhostv2-not_there"     //testing and development override
    const hostName = setHost;
    //console.log('Host is: ' + hostName);

    this.setHostName(hostName);
    const host_config = await this.retriveHostConfig(hostName);

   // console.log('should have the host config.  It is: ' + JSON.stringify(host_config, null, 2));

    this.setHostConfig(host_config);
    this.setLocalStyleClasses(host_config);
  }

   async initHostViaParam(hostName: string) {
    console.log('init host go on: ' + hostName);
    this.setHostName(hostName)
    //const host_config = await this.retriveHostConfig(hostName).then((host_config)=>{

    await this.retriveHostConfig(hostName).then((host_config)=>{
      this.setHostConfig(host_config);
      this.setLocalStyleClasses(host_config)
      this.host_available = true;
      console.log('host init completed');  
    })
    .catch((err)=>{
      console.error('error loading host critical failure')
      //rejects(err)
    })
  }




  setLocalStyleClasses(host_config: AppHost) {
    const css = `
    .host-primary-bg { background-color: ${host_config.theme.primary_color} !important;}
    .host-accent-bg { background-color: ${host_config.theme.accent_color} !important;}
    .host-primary { color: ${host_config.theme.primary_color} !important;}
    .host-accent { color: ${host_config.theme.accent_color} !important;}
    .host-primary-btn { background-color: ${host_config.theme.primary_btn.fill_color} !important; color: ${host_config.theme.primary_btn.txt_color} !important;}
    .host-accent-btn { background-color: ${host_config.theme.accent_btn.fill_color} !important; color: ${host_config.theme.accent_btn.txt_color} !important;}
    .host-border-primary { border-color: ${host_config.theme.primary_color} !important;}
    .host-system-bg { background-repeat: no-repeat !important; background-size: cover !important; background-position: 0; background-image: url(${host_config?.theme?.background_url}) !important; background-color: ${host_config.theme.primary_color} !important; }
    `,
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    head.appendChild(style);
    style.appendChild(document.createTextNode(css));
  }

  
  async retriveHostConfig(host_name: string) {
    try {
      
      console.log('Getting host: ' + host_name);
      const authToken =  'Bearer ' + this.authService.getAuthToken();
    //  console.log('authToken ' + authToken);
      const response = await this.http
        .post<AppHost>(
          environment.api.host.retrieve,
          {
            host_name,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : authToken,
            },
            responseType: 'json',
          }
        )
        .toPromise();
   //   console.log('Got a host: ' + JSON.stringify(response, null, 2));
      // If the request is successful, set host_available to true
      this.host_available = true;

      return response;
    } catch (e) {
      console.log('error is: ' + JSON.stringify(e, null, 2));
      this.host_available = false;
      throw e;
    }
}


  setHostConfig(host_config: AppHost): void {
    SetVal(this.host_config, host_config);
  }

  get currentHostConfig(): AppHost {
    return GetVal(this.host_config);
  }
  get currentHostConfig$(): Observable<AppHost> {
    return GetVal$(this.host_config);
  }

  setHostName(host_name: string): void {
    SetVal(this.host_name, host_name);
  }

  get currentHostName(): string {
    return GetVal(this.host_name);
  }
  get currentHostName$(): Observable<string> {
    return GetVal$(this.host_name);
  }
}
