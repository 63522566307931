import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as firebase from 'firebase/app';
import { environment } from '@yoyo/env';
//import 'rxjs/add/operator/toPromise';
import { CloudStorageService } from '@yoyo/services';

@Injectable({
    providedIn: 'root'
  })

export class AuthService {
    private authToken: any;
    private authServiceToken: any;
    private userToken: any;
    private curUser: any ={'loggedIn':false};
    constructor(
        private http: HttpClient, 
        private fs: CloudStorageService,
        ){ 
        this.fs.initFirebase();}

    async getToken(): Promise<void> {
        try {
       //     console.log('we are go for auth get');
            await firebase.default.auth().signInWithEmailAndPassword(environment.account.email,environment.account.password)
            const authToken = await firebase.default.auth().currentUser.getIdToken()
            this.authServiceToken = authToken;
            
        } catch (error) {
            console.log('error: ' + JSON.stringify(error))
        }
    }

    async getRoles(){
      try {
          const authToken =  'Bearer ' + this.getUserToken();
          const response = await this.http
            .post<any>(
              environment.api.auth.getRoles,
              {},
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization' : authToken,
                },
                responseType: 'json',
              }
            )
            .toPromise();    
          return response;
        } catch (e) {
          console.log('error is: ' + JSON.stringify(e, null, 2));
          throw e;
        }
    }

    doGoogleLogin(){
      return new Promise<any>((resolve, reject) => {
        let provider = new firebase.default.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        firebase.default.auth()
          .signInWithPopup(provider)
            .then(res => {
              this.setUser(res.user);
              //console.log('Google: ' + JSON.stringify(res,null,2))
              if (res.additionalUserInfo.isNewUser) {
                console.log('new userr');
              } else {
                console.log('Welcome back carter');
              }
              resolve(res);
            }, err => {
              console.log(err);
               reject(err);
            })
      })
    }


    getAuthToken(): string {
        return this.authServiceToken;
      }

      doRegister(value: any){
        const displayName = value.fName + ' ' + value.lName;
        return new Promise<any>((resolve, reject) => {
            firebase.default.auth().createUserWithEmailAndPassword(value.email, value.password)
          .then(res => {
            console.log('registered');
            //this.doLogin(value)
            this.setUser(res.user)
            .then(()=>{
              console.log('logged in and user set');
              resolve(res);
            })
          }, err => reject(err))
        })
      }
    
      updateProfile(displayName: string): Promise<void> {
        return new Promise((resolve, reject) => {
          const user = this.getuser();
          //console.log('user: ' + JSON.stringify(user))
          user.updateProfile({ displayName: displayName })
            .then(() => {
              //console.log('Profile updated successfully');
              resolve();
            })
            .catch((error: any) => {
              console.error('Error updating profile:', error);
              reject(error);
            });
        });
      }

      getAuthServiceToken(): string {
        return this.authServiceToken;
      }

      updateRoles() {
        const user = this.getuser()
        const roleAssign = {"functions":false,"roleSet":false,"hostEdit":false, "userGet":false}
        //roles for baselin user = {"functions":false,"roleSet":false,"hostEdit":false, "userGet":false}
        try {
          const authToken =  'Bearer ' + this.getAuthServiceToken();
          //console.log('got authoekn of user in the service: ' + JSON.stringify(authToken,null,2))
          const response = this.http
            .post<any>(
              environment.api.auth.assignRole,
              {
                'uid': user.uid,
                'role' : roleAssign
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization' : authToken,
                },
                responseType: 'json',
              }
            )
            .toPromise();    
          return response;
        } catch (e) {
          console.log('error is: ' + JSON.stringify(e, null, 2));
          throw e;
        }
      }














      doLogin(value: any) {
        return new Promise<any>((resolve, reject) => {
          firebase.default.auth().signInWithEmailAndPassword(value.email, value.password)
            .then(res => {
           //   console.log('Login response: ' + JSON.stringify(res, null, 2));
              return this.setUser(res.user);
            })
            .then(() => {
              resolve(true);
            })
            .catch(err => reject(err));
        });
      }
    
      doLogout(){
        return new Promise((resolve, reject) => {
          if(firebase.default.auth().currentUser){
            firebase.default.auth().signOut()
          }
          else{
            reject();
          }
        });
      }

      async setUser(val: any){
        this.curUser = val;
        const authToken = await firebase.default.auth().currentUser.getIdToken()
        this.userToken = authToken;
      }

      getuser(){
        return this.curUser;
      }

      getUserToken(): string {
        return this.userToken;
      }
  
      pswdReset(email: string){
        return new Promise<any>((resolve, reject) => {
        firebase.default.app().auth().sendPasswordResetEmail(email)
          .then(()=>{
           // console.log('sent an email');
            resolve(true);
          })
          .catch((err)=>{
           // console.log('Pwd reset email error: ' + JSON.stringify(err,null,2));
            reject(err);
          })
        })
      }
      
}