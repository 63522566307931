import { Component, OnInit } from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import { HostStateService, AppStateService, AuthService, ReactionService, UsersService } from '@yoyo/services';
import {DynamicComponent} from '../setup/setup.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common'
import { environment } from '@yoyo/env';

@Component({
  selector: 'step-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['menu.scss']
})
export class menu implements  DynamicComponent, OnInit {      //step 16
  current_reaction: Reaction;
  current_host_config: AppHost;
  hostConfigForm: FormGroup;
  skiptoForm: FormGroup;
  userName: string;
  hostForm: FormGroup;
  availableHosts: string[] = [];
  selectedHost: string; 
  hostLoaded: boolean = true;
  hostBtnLabel: string = "loading";
  hostDownloading: boolean = true;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private rs: ReactionService,
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private location: Location,
    private usersService: UsersService,

  ) {
  //  this.current_reaction = this.app_state.current_reaction;
   // this.current_host_config = this.host_state.currentHostConfig;
  }

  ngOnInit() {
    //console.log('init menu');
    //console.log('getuser '+ JSON.stringify(this.auth.getuser(),null,2));
    this.userName = this.auth.getuser().displayName
    this.app_state.fileRoot = eval('this.auth.' + environment.fileSystem.root);

    if (this.host_state.host_available) {
      console.log('Host state is set')
      this.hostLoaded = true;
      this.hostBtnLabel = "edit";
      this.hostDownloading = false;
      this.selectedHost = this.host_state.currentHostName
      this.hostForm.patchValue({
        host: this.selectedHost,
      });
    } else {
      //console.log('Setting host state')
      this.setAvailableHosts();
    }
    
    this.hostForm = this.fb.group({
      host: [this.selectedHost],      
    });

    this.hostForm.valueChanges.subscribe(value => {
      //console.log('this.host_state.currentHostName: ' + this.host_state.currentHostName);
      //console.log('value.overlayState: ' + JSON.stringify(value,null,2));
      if (this.host_state.currentHostName != value.host) {
        this.hostLoaded = false;
       // console.log('showing false');
        this.hostBtnLabel = "Apply";
      } else {
        //console.log('showing true');
        this.hostLoaded = true;
        this.hostBtnLabel = "Edit";
      }
    })

    this.selectedHost = this.availableHosts[this.availableHosts.length -1];
    this.hostForm.patchValue({
      host: this.selectedHost,
    });
  }

  hostAssessmnet():string {
    const rawLoc = document.location.hostname;
    if (rawLoc.includes('localX')) {
      console.log('go for local')
      return 'yoyo.dev.reply.gift'
    };
    if (rawLoc.includes('.dev.')) {
      console.log('go for local')
      return 'yoyo.dev.reply.gift'
    };
    return 'yoyo.reply.gift'
  }


  btnHandler(){
    console.log('this.hostLoaded: ' + this.hostLoaded);
    if (this.hostLoaded){
      this.gotoReactionSetup()
    } else {
      this.getHost().then(()=>{
        console.log('got the host - nopw reaction');
        this.gotoReactionSetup()
      })
      .catch((err) =>{
        console.log('crtical host laoding fault');
      })
    }
  };


  async getHost(){
    console.log('go for host get')
    this.hostDownloading = true;
    //this.selectedHost = this.hostForm.get('host').value

    this.selectedHost = environment.hosts.default

    await this.host_state.initHostViaParam(this.selectedHost)
    .then(()=>{
        console.log('Host downloaded and applied');
        this.hostDownloading = false;
        this.hostBtnLabel = "Ready";
        this.hostLoaded = true;
    })
    .catch((e)=>{
      this.hostDownloading = false;
      console.log('Error setting host: ' + JSON.stringify(e,null,2))
      this.router.navigate(['/broken']);
    })
  }

  gotoReactionSetup(){
    this.app_state.quickLoad = true;
    this.app_state.preview = false;
    if (!this.app_state.current_reaction) {
      this.rs.reaction = this.rs.initiliseBlankReaction();
      this.app_state.setReaction(this.rs.reaction);
    }
    //console.log('reaction: ' + JSON.stringify(this.rs.reaction, null,2));
    this.router.navigate(['/v1/_init']);
  }

  viewList(){
    console.log('viewList')
    this.app_state.preview = true;
    this.router.navigate(['/view'])
  }

  setAvailableHosts(){
    this.usersService.getEligibility()
    .then((eligibleHosts)=>{
      //console.log('eligibleHosts: ' + JSON.stringify(eligibleHosts,null,2));

      //console.log('eligibleHosts'+ eligibleHosts);
      //console.log('eligibleHosts.host' + eligibleHosts.hosts);
      //console.log('eligibleHosts.hosts.length ' + eligibleHosts.hosts.length );


      if (eligibleHosts.hosts.length === 0) {
        this.router.navigate(['/broken']);
      }

      if (eligibleHosts.hosts.length === 1 ) {
        //console.log('Found just the one');
        this.selectedHost = eligibleHosts.hosts[0].hostName;
        this.availableHosts.push(eligibleHosts.hosts[0].hostName);
        this.host_state.initHostViaParam(this.selectedHost)
        .then(()=>{
            //console.log('go for init react');
            this.hostLoaded = true;
            this.hostBtnLabel = "New reply";
            this.hostDownloading = false;
            this.hostForm.patchValue({
              host: this.selectedHost,
            });
        })
        .catch((e)=>{
          console.log('Error setting host: ' + JSON.stringify(e,null,2))
          const index = this.availableHosts.indexOf(this.selectedHost);
          if (index !== -1) {
            this.availableHosts.splice(index, 1);
          } else {
            this.router.navigate(['/broken']);
          }
          if (this.availableHosts.length > 0) {
            this.selectedHost = this.availableHosts[this.availableHosts.length -1]
          } else {
            this.router.navigate(['/broken']);
          }
        })
      } else {
        if (eligibleHosts.hosts.length > 1){
          eligibleHosts.hosts.forEach((host: any) => {
            if (host && host.hostName) {
              this.availableHosts.push(host.hostName);
            }
          });
          if (this.availableHosts.length === 0){
            this.router.navigate(['/broken']);
          } else {
            this.selectedHost = this.availableHosts[this.availableHosts.length -1]
            this.hostLoaded = true;
            this.hostBtnLabel = "New reply";
            this.hostDownloading = false;
            this.hostForm.patchValue({
              host: this.selectedHost,
            });
          }
        }
      }
    })
    .catch((err)=>{
      console.log('Err getting elgible hosts: ' + JSON.stringify(err,null,2))
    })
  }
}
