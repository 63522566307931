import { Pipe, PipeTransform } from '@angular/core';
import { Reaction } from '@yoyo/types';

@Pipe({ name: 'replaceHostVariable' })
export class ReplaceHostVariablePipe implements PipeTransform {
  transform(string: string, reactionData: Reaction): string {
    return string
      .replace('{{sender.first_name}}',reactionData?.sender_details?.first_name)
      .replace('{{sender.last_name}}', reactionData?.sender_details?.last_name)
      .replace('{{receiver.first_name}}', reactionData?.receiver_details?.first_name)
      .replace('{{gift_image}}', reactionData?.product?.product_details?.image_src || '')
      .replace('{{gift_description}}', reactionData?.product?.product_details?.product_description || '')
      .replace('{{gift_message}}', reactionData?.gift_message || '')
      .replace('{{gift_name}}', reactionData?.product?.product_details?.label || '' );
  }
}
