import { Component, AfterViewInit } from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import { HostStateService, AppStateService, TelemetryService } from '@yoyo/services';
import {DynamicComponent} from "../../setup/setup.component";

@Component({
  selector: 'step-request-permission',
  templateUrl: './request.component.html',
})
export class ReactionPermissionRequestStep implements DynamicComponent, AfterViewInit  {       //this is step 3
  current_reaction: Reaction;
  current_host_config: AppHost;
  okStep: number;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private telemetry: TelemetryService
  ) {
    this.telemetry.startRecordingLoad();
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
    this.okStep = this.app_state.cameraOkReturnStep;
    this.attemptPermissionCheck();
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
  }


  async attemptPermissionCheck(): Promise<void> {       
    this.telemetry.startRecordingUser();            
    try {
      const mediaConstraints: MediaStreamConstraints = {
        video: true,
        audio: true,
      };
      await navigator.mediaDevices.getUserMedia(mediaConstraints);
      await this.onPermissionAccept();
    } catch (e) {
      
      console.log('got an error: ' + e);
      this.app_state.cameraState = e as string;
      console.log('this.app_state.cameraState: ' + this.app_state.cameraState);
     await this.onPermissionDeny();
  //   await this.onPermissionAccept();
    }
  }


  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(3, this.current_host_config?.app_content?.experience?.step?.step3?._module, next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }


  async onPermissionAccept(): Promise<void> {
    try {
      this.telemetryRecord(this.okStep);
      this.app_state.cameraState = 'ready';
      this.app_state.reactionStepValue$.next(this.okStep);
    } catch (e) {
      console.log('onPermissionAccept() Error', e);

      // JM TODO - Handle App Failure
    }
  }

  async onPermissionDeny(): Promise<void> {
    this.telemetryRecord(4);
    try {
      this.app_state.reactionStepValue$.next(4);
    } catch (e) {
      console.log('onPermissionDeny() Error', e);
      // JM TODO - Handle App Failure
    }
  }
}
