import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'player-no-controls',
  template: `
    <video
      class="rounded-xl h-full w-full object-cover"
      [poster]="posterURL"
      #video_player
      webkit-playsinline
      playsinline
    >
      <source [src]="videoURL" type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video>
  `,
})
export class PlayerNoControlsComponent {
  @Input() videoURL?: string;
  @Input() posterURL?: string;
  @ViewChild('video_player') video_player: ElementRef<HTMLVideoElement>;
}
