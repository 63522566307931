import { Component, AfterViewInit } from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import { HostStateService, AppStateService, TelemetryService } from '@yoyo/services';
import {DynamicComponent} from '../../setup/setup.component';

@Component({
  selector: 'step-response-options',
  templateUrl: './options.component.html',
})
export class ReactionResponseOptionsStep implements DynamicComponent, AfterViewInit  {           //step 6
  current_reaction: Reaction;
  current_host_config: AppHost;
  prmyBtnText: string = "Video";
  scndBtnText: string = "Write";
  backBtnText: string = "Back";
  overlayValue: string;
  imgSrc: string;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private telemetry: TelemetryService,
  ) {
    this.telemetry.startRecordingLoad();
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
    //this.imgSrc = this.app_state.current_reaction.product.product_details.image_src
    this.imgSrc = this.replaceConfigString(this.current_host_config.app_content.experience.step.step6.img );
    this.prmyBtnText = this.current_host_config.app_content.experience.step.step6.prmy_btn;
    this.scndBtnText = this.current_host_config.app_content.experience.step.step6.scnd_btn;
    this.backBtnText = this.current_host_config.app_content.experience.step.step6.thrd_btn;
    this.overlayValue = this.replaceConfigString(this.current_host_config.app_content.experience.step.step6.overalLayValue);
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(6, this.current_host_config?.app_content?.experience?.step?.step6?._module, next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

  routeToWatchAgain() {
    this.telemetryRecord(2);
    this.app_state.reactionStepValue$.next(2);
  }

  routeToWriteResponse() {
    this.telemetryRecord(8);
    this.app_state.reactionStepValue$.next(8);
  }

  routeToRecordResponse() {
    this.telemetryRecord(3);
    this.app_state.cameraOkReturnStep = 7;        //this will set that if permission ok then come back to recording post
    this.app_state.cameraFailReturnStep = 8;        //this will set that if fails then go back to write step
    this.app_state.cameraNextText = "send a message!";
    this.app_state.deniedNextBtn = "Send msg";
    this.app_state.reactionStepValue$.next(3);
  }

  private replaceConfigString(string_value: string) {
    return string_value
      .replace(
        '{{sender.first_name}}',
        this.current_reaction?.sender_details?.first_name
      )
      .replace(
        '{{sender.last_name}}',
        this.current_reaction?.sender_details?.last_name
      )
      .replace(
        '{{receiver.first_name}}',
        this.current_reaction?.receiver_details?.first_name
      )
      .replace(
        '{{gift_name}}',
        this.current_reaction?.product?.product_details?.label || ''
      )
      .replace(
        '{{gift_image}}',
        this.current_reaction?.product?.product_details?.image_src || ''
      )
      .replace(
        '{{gift_description}}',
        this.current_reaction?.product?.product_details?.product_description || ''
      )
      .replace('{{gift_message}}', this.current_reaction?.gift_message || '');
  }



}
