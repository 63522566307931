import {Component, AfterViewInit} from '@angular/core';
import {AppHost, Reaction} from '@yoyo/types';
import {AppStateService, HostStateService, TelemetryService} from '@yoyo/services';
import {DynamicComponent} from '../../setup/setup.component';

@Component({
  selector: 'step-retryfault',
  templateUrl: './retryfault.component.html',
})
export class ReactionRetryFaultStep implements DynamicComponent, AfterViewInit {
  current_reaction: Reaction;
  current_host_config: AppHost;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private telemetry: TelemetryService,
  ) {
    this.telemetry.startRecordingLoad();
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  routeBack() {
    console.log('this.app_state.faultReturnStep ' + this.app_state.faultReturnStep);
    if (typeof this.app_state.faultReturnStep === 'undefined'){
      this.telemetryRecord(0);
      this.app_state.reactionStepValue$.next(0);
    } else {
      this.telemetryRecord(this.app_state.faultReturnStep);
      this.app_state.reactionStepValue$.next(this.app_state.faultReturnStep);
    }
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(14, 'Retry', next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }
}