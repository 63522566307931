import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrimaryLayout } from '@yoyo/layouts';
import { CreateReactionComponent } from '@yoyo/pages';
import { LoginComponent } from './pages/create/components/steps/login/login.component'
import { RegisterComponent } from './pages/create/components/steps/register/register.component'
import { menu } from './pages/create/components/steps/menu/menu.component'
import { unauthorised } from './pages/create/components/steps/unauthorised/unauthorised.component'
import { BrokenStep } from './pages/create/components/steps/broken/broken.component'
import { LoggedInGuard } from './guards';
import { ResetComponent } from './pages/create/components/steps/reset/reset.component'
import { ViewList } from './pages/create/components/steps/viewlist/viewlist.component';
import { thankyouStep } from './pages/create/components/steps/thankyou/thankyou.component';
import { LandingComponent } from './pages/create/components/steps/landing/landing.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'landing' // Redirect empty path to 'landing'
  },
  {
    path: 'v1',
    component: PrimaryLayout,
    canActivate: [LoggedInGuard], 
    children: [
      {
        path: ':id',
        component: CreateReactionComponent,
      },
    ],
  },
  {
    path: 'landing', // Define the landing route
    component: LandingComponent, // Replace 'LandingComponent' with your actual landing component
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'reset',
    component: ResetComponent,
  },
  {
    path: 'unauthorised',
    component: unauthorised,
  },
  {
    path: 'broken',
    canActivate: [LoggedInGuard], 
    component: BrokenStep,
  },
  {
    path: '_init',
    canActivate: [LoggedInGuard], 
    component: menu,
  },
  {
    path: 'view',
    canActivate: [LoggedInGuard], 
    component: ViewList,
  },
  {
    path: 'thanks',
    canActivate: [LoggedInGuard], 
    component: thankyouStep,
  },
  { path: '**', pathMatch: 'full', redirectTo: '_init' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
