import { Component, AfterViewInit } from '@angular/core';
import {AppHost, Reaction} from '@yoyo/types';
import { HostStateService, AppStateService, TelemetryService } from '@yoyo/services';
import { DynamicComponent } from '../setup/setup.component';

@Component({
  selector: 'step-offer',
  templateUrl: './offer.component.html',
})
export class ReactionOfferStep implements DynamicComponent {      //step 9
  current_reaction: Reaction;
  current_host_config: AppHost;
  header: string
  body: string
  prmy_btn: string
  private prmy_url: string
  scnd_btn: string
  private scnd_url: string
  img: string

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private telemetry: TelemetryService,
  ) {
    this.telemetry.startRecordingLoad();
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
    this.header = this.current_host_config?.app_content?.experience?.step?.step9?.heading;
    this.body = this.current_host_config?.app_content?.experience?.step?.step9?.body;
    this.prmy_btn = this.current_host_config?.app_content?.experience?.step?.step9?.prmy_btn;
    this.prmy_url = this.app_state.current_reaction?.offers?.offer_experience?.prmy_url;
    this.scnd_btn = this.current_host_config?.app_content?.experience?.step?.step9?.scnd_btn;
    this.scnd_url = this.app_state.current_reaction.offers?.offer_experience?.scnd_url;
    this.img = this.current_host_config?.app_content?.experience?.step?.step9?.img;
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  async prmyBtn() {
    //console.log('primary button pressed - url is: ' + this.prmy_url);
    this.telemetryRecord(-9);
    window.location.href = this.prmy_url;
  }
  async scndBtn() {
    //console.log('secondary button pressed')
    this.telemetryRecord(-9);
    window.location.href = this.scnd_url;
 }

 private telemetryRecord(next: number) {
    this.telemetry.stopRecording(9, this.current_host_config?.app_content?.experience?.step?.step9?._module, next);
    // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

}
