import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Reaction } from '@yoyo/types';
import {
  SetVal,
  GetVal,
  GetVal$,
  Empty$,
  TrySetValFromCache,
} from '@yoyo/shared';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private current_reaction_value = Empty$<Reaction>('current_reaction_value');
  public reactionStepValue$ = new BehaviorSubject(0);
  public redoButtonToPointToStep: number;
  public videoId: string;
  public returnStep: number;
  public cameraOkReturnStep: number;
  public cameraFailReturnStep: number;
  public cameraState: string = 'not set';
  public cameraNextText: string;
  public deniedNextBtn: string;
  public reactionReady: boolean = false;
  public faultReturnStep: number;
  public telemetryIndex: number = -1;
  public curStatus = "Got the boom";
  public preview: boolean = false;
  public quickLoad: boolean = false;
  public fileRoot: string = "";

  constructor() {}

  setReaction(current_reaction: Reaction): void {
    console.log("pos_3");
    SetVal(this.current_reaction_value, current_reaction);
  }

  get current_reaction(): Reaction {
    return GetVal(this.current_reaction_value);
  }
  get current_reaction$(): Observable<Reaction> {
    return GetVal$(this.current_reaction_value);
  }

  clearStorage(): void {
    localStorage.clear();
  }

  retrieveReactionFromCache() {
    TrySetValFromCache(this.current_reaction_value);
  }
}
